const DOCUMENT = 'QRCODE_';

const actions = {
    GET_QRCODE: 'GET_QRCODE',
    UPDATE_QRCODE: 'UPDATE_QRCODE',
    UPDATE_QRCODE_SAGA: 'UPDATE_QRCODE_SAGA',
    SELECT_CURRENT_QRCODE: 'SELECT_CURRENT_QRCODE',
    TOGGLE_VIEW: 'QRCODE_TOGGLE_VIEW',
    UPDATE_EDIT_QRCODE: 'QRCODE_UPDATE_EDIT_QRCODE',
    LOAD_FROM_FIRESTORE: DOCUMENT + 'LOAD_FROM_FIRESTORE',
    LOAD_FROM_FIRESTORE_SUCCESS: DOCUMENT + 'LOAD_FROM_FIRESTORE_SUCCESS',
    LOAD_FROM_FIRESTORE_ERROR: DOCUMENT + 'LOAD_FROM_FIRESTORE_ERROR',

    SAVE_INTO_FIRESTORE: DOCUMENT + 'SAVE_INTO_FIRESTORE',
    SAVE_INTO_FIRESTORE_ERROR: DOCUMENT + 'SAVE_INTO_FIRESTORE_ERROR',

    RESET_FIRESTORE_DOCUMENTS: DOCUMENT + 'RESET_FIRESTORE_DOCUMENTS',
    RESET_FIRESTORE_DOCUMENTS_ERROR: DOCUMENT + 'RESET_FIRESTORE_DOCUMENTS_ERROR',

    TOGGLE_FIRESTORE_HANDLE_MODAL: DOCUMENT + 'TOGGLE_FIRESTORE_HANDLE_MODAL',
    FIRESTORE_UPDATE: DOCUMENT + 'FIRESTORE_UPDATE',

    loadFromFireStore: () => {
        return { type: actions.LOAD_FROM_FIRESTORE };
    },

    loadFromFireStoreSuccess: data => ({

        type: actions.LOAD_FROM_FIRESTORE_SUCCESS,
        payload: { data },
    }),

    loadFromFireStoreError: error => ({
        type: actions.LOAD_FROM_FIRESTORE_ERROR,
        payload: { error },
    }),

    saveIntoFireStore: (data, actionName = 'insert') => ({
        type: actions.SAVE_INTO_FIRESTORE,
        payload: { data, actionName },
    }),

    toggleModal: (data = null) => ({
        type: actions.TOGGLE_FIRESTORE_HANDLE_MODAL,
        payload: { data },
    }),

    update: data => ({
        type: actions.FIRESTORE_UPDATE,
        payload: { data },
    }),

    saveIntoFireStoreError: error => ({
        type: actions.SAVE_INTO_FIRESTORE_ERROR,
        payload: { error },
    }),

    resetFireStoreDocuments: () => ({
        type: actions.RESET_FIRESTORE_DOCUMENTS,
    }),

    resetFireStoreDocumentsError: error => ({
        type: actions.RESET_FIRESTORE_DOCUMENTS_ERROR,
        payload: { error },
    }),

    initData: () => ({ type: actions.GET_QRCODE }),
    deleteqrCode: selected => {
        return (dispatch, getState) => {

            const qrCodes = getState().qrCodes.qrCodes;
            const newqrCodes = [];
            qrCodes.forEach(qrCode => {
                const selectedIndex = selected.indexOf(qrCode.key);
                if (selectedIndex === -1) {
                    newqrCodes.push(qrCode);
                }
            });
            dispatch({ type: actions.UPDATE_QRCODE_SAGA, qrCodes: newqrCodes });
        };
    },
    updateqrCode: qrCode => {
        return (dispatch, getState) => {
            const qrCodes = getState().qrCodes.qrCodes;
            const index = qrCodes
                .map(usr => usr.id)
                .indexOf(qrCode.id);
            if (index === -1) {
                qrCodes.push(qrCode);
            } else {
                qrCodes[index] = qrCode;
            }
            dispatch({ type: actions.UPDATE_QRCODE_SAGA, qrCodes, qrCode });
        };
    },
    selectCurrentqrCode: id => ({ type: actions.SELECT_CURRENT_QRCODE, id }),
    toggleView: view => ({ type: actions.TOGGLE_VIEW, view }),
    editqrCode: qrCode => ({ type: actions.UPDATE_EDIT_QRCODE, qrCode })
};
export default actions;