import React, { Component } from 'react';
import Button from '../uielements/button';
import Input from '../uielements/input';
import Modal from '../feedback/modal';
import { notification } from '../index';
import Firebase from '../../helpers/firebase/index';

import Logo from '../../image/logo.png';
import { Link } from 'react-router-dom';
import IntlMessages from '../utility/intlMessages';

export default class extends Component {
	state = {
		loginVisible: false,
		resetPasswordVisible: false,
		Username: '',
		surname: '',
		email: '',
		password: '',
		sapCode: '',
		admin: '',
		confirmLoading: false
	};
	showModal = () => {
		// this.setState({ loginVisible: true, resetPasswordVisible: false });
		this.props.history.push('/');
	};
	handleCancel = e => {
		this.setState({ loginVisible: false, resetPasswordVisible: false });
	};

	handleRegister = async () => {
		const {
			Username,
			surname,
			sapCode,
			email,
			password,
			admin
		} = this.state;
		if (!(email && password)) {
			notification('error', 'Favor de llenar el  correo y contraseña');
			return;
		}

		this.setState({ confirmLoading: true });
		const self = this;
		let data;

		data = await Firebase.signup(Firebase, {
			Username,
			surname,
			sapCode,
			email,
			password,
			admin
		});
		const user = data && data.user ? data.user : false;

		const message =
			user === false && data && data.message
				? data.message
				: 'Lo sentimos un error ha ocurrido';
		if (user) {
			const token = await user.getIdToken();
			this.props.signup(token);
			this.props.history.push('/dashboard');
		} else {
			notification('error', message);
			self.setState({ confirmLoading: false });
		}
	};

	handleLogin = async () => {
		const { email, password, sapCode } = this.state;

		if (!(email && password)) {
			notification('error', 'Favor de llenar el  correo y contraseña');
			return;
		}
		this.setState({ confirmLoading: true });
		const self = this;
		let data;
		data = await Firebase.login(Firebase.EMAIL, {
			email,
			password,
			sapCode
		});
		const user = data && data.user ? data.user : false;
		const message =
			user === false && data && data.message
				? 'Usuario y/o contraseña incorrectos'
				: 'Ups! Tenemos un Problema';
		this.setState({ visible: false });

		if (user) {
			const { uid } = user;
			const compareData = await Firebase.getUser({ userId: uid });

			if (!compareData || !compareData.data) {
				console.log('error no esta registrado el usuario');
			}
			const status = compareData.data.status;
			const userRole = compareData.data.role;

			if (status === 0) {
				const message = 'La cuenta de usuario aun no ha sido aprobada';
				notification('warning', message);
				self.setState({ confirmLoading: false, visible: false });
			} else {
				if (status === 1) {
					const token = await user.getIdToken();
					const uid = user.uid;
					const userName = compareData.data.Username;
					const surName = compareData.data.surname;
					const email = user.email;
					const displayName = user.displayName;
					const phoneNumber = user.phoneNumber;
					const photoURL = user.photoURL;
					const admin = await Firebase.isAdmin(uid);
					this.props.login(
						token,
						uid,
						userRole,
						userName,
						surName,
						email,
						displayName,
						phoneNumber,
						photoURL,
						admin
					);
					this.props.history.push('/dashboard');
				}
			}
		} else {
			notification('error', message);
			self.setState({ confirmLoading: false });
		}
	};

	resetPassword = () => {
		const { email = '' } = this.state;
		if (email.trim().length === 0) {
			notification('error', `Escribe tu correo electronico.`);
			return;
		}

		Firebase.resetPassword(email)
			.then(() =>
				notification(
					'success',
					`Contraseña temporal fue enviada al correo ${email}.`
				)
			)
			.catch(error =>
				notification('error', 'Correo electronico no encontrado.')
			);
		this.setState({
			email: '',
			resetPasswordVisible: false
		});
	};

	showResetModal = () => {
		this.setState({ resetPasswordVisible: true, loginVisible: false });
	};
	render() {
		return (
			<div>
				<Button
					type="danger"
					onClick={this.showModal}
					className="btnFirebase"
				>
					Iniciar Sesión
				</Button>
				<Modal
					visible={this.state.loginVisible}
					confirmLoading={this.state.confirmLoading}
					onCancel={this.handleCancel}
					onOk={this.handleLogin}
					className="isoFirebaseLoginModal"
					cancelText="Cancelar"
					okText="Ingresar"
				>
					<form>
						<div className="isoInputWrapper">
							<img
								width="150px"
								src={Logo}
								alt="Contact Center"
							/>
							<br />
							<label>Usuario</label>
							<Input
								ref={email => (this.email = email)}
								size="large"
								placeholder="ejemplo@gmail.com"
								value={this.state.email}
								onChange={event => {
									this.setState({
										email: event.target.value
									});
								}}
							/>
						</div>
						<div
							className="isoInputWrapper"
							style={{
								marginBottom: 10
							}}
						>
							<label>Contraseña</label>
							<Input
								type="password"
								size="large"
								placeholder="Contraseña"
								value={this.state.password}
								onChange={event => {
									this.setState({
										password: event.target.value
									});
								}}
							/>
						</div>
						<span
							className="isoResetPass"
							onClick={this.showResetModal}
						>
							<a>¿Olvidaste la Contraseña?</a>
						</span>
						<br /> <br/>
						<span>
							<Link to="/signup">
								<IntlMessages id="page.signInCreateAccount" />
							</Link>
						</span>
					</form>
				</Modal>
				<Modal
					visible={this.state.resetPasswordVisible}
					confirmLoading={this.state.confirmLoading}
					onCancel={this.handleCancel}
					onOk={this.resetPassword}
					className="isoFirebaseLoginModal"
					cancelText="Cancelar"
					okText="Enviar"
				>
					<h2>Recuperar Contraseña</h2>
					<br />
					<label>Correo Electrónico</label>
					<Input
						ref={email => (this.email = email)}
						size="large"
						placeholder="correo@mail.com"
						value={this.state.email}
						onChange={event => {
							this.setState({ email: event.target.value });
						}}
					/>
				</Modal>
			</div>
		);
	}
}
