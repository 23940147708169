import React, {Component} from "react";
import {Link, Redirect} from 'react-router-dom';
import {connect} from "react-redux";
import {Layout} from "antd";

//Redux
import authAction from '@iso/redux/auth/actions';
import appAction from "@iso/redux/app/actions";

//components
import TopbarUser from "./topbarUser";
import AxaltaTopbarWrapper from "./topbar.style";
import IntlMessages from '@iso/components/utility/intlMessages';

//assets
import Logo from "@iso/image/logo.png";
import LogoAxalta from "@iso/image/logoAxalta.png";
import background_header from "@iso/image/bar-top.jpg";

//Firebase
import Firebase from '@iso/helpers/firebase';
import FirebaseLogin from '@iso/components/firebase';

//Dropdown Options

import Dropdown, {DropdownMenu, MenuItem, SubMenu} from '@iso/components/uielements/dropdown';

import Whatsapp from '@iso/components/whatsapp';


const {Header} = Layout;
const {toggleCollapsed, clearMenu} = appAction;
const {login, isAdmin} = authAction;

class AxaltaTopbar extends Component {

    state = {
        redirectToReferrer: false
    };

    constructor(props) {
        super(props);
        this.handleClick = this
            .handleClick
            .bind(this);
        this.onOpenChange = this
            .onOpenChange
            .bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.isLoggedIn !== nextProps.isLoggedIn && nextProps.isLoggedIn === true) {
            this.setState({redirectToReferrer: true});
            console.log(this.props.app.view);
        }
    }
    handleLogin = (token = false, uid = false) => {
        const {login, clearMenu, admin} = this.props;
        if (token) {
            login(token, admin, uid);

        } else {
            login();
        }
        clearMenu();
        this
            .props
            .history
            .push('/dashboard');
    };

    handleClick(e) {
        this
            .props
            .changeCurrent([e.key]);
        if (this.props.app.view === "MobileView") {
            setTimeout(() => {
                this
                    .props
                    .toggleCollapsed();
                this
                    .props
                    .toggleOpenDrawer();
            }, 100);
        }
    };

    onOpenChange(newOpenKeys) {
        const {app, changeOpenKeys} = this.props;
        const latestOpenKey = newOpenKeys.find(key => !(app.openKeys.indexOf(key) > -1));
        // const collapsed = this.props.collapsed && !this.props.openDrawer;
        // const styling = {
        //     position: "fixed",
        //     width: "100%",
        //     height: 70
        // };
        const latestCloseKey = app
            .openKeys
            .find(key => !(newOpenKeys.indexOf(key) > -1));
        let nextOpenKeys = [];
        if (latestOpenKey) {
            nextOpenKeys = this
                .getAncestorKeys(latestOpenKey)
                .concat(latestOpenKey);
        }
        if (latestCloseKey) {
            nextOpenKeys = this.getAncestorKeys(latestCloseKey);
        }
        changeOpenKeys(nextOpenKeys);
    };

    getAncestorKeys = key => {
        const map = {
            sub3: ["sub2"]
        };
        return map[key] || [];
    };

    displayMenu() {

        const {history} = this.props;
        const {locale} = this.props;
        const {isLoggedIn} = this.props;
        const {admin} = this.props;
        const {view} = this.props;
        // const demoStyle = {
        //     marginBottom: '8px',
        //     marginRight: '8px'
        // };
        const styles = {
            'padding': '0px 20px'
        }
        // const styling = {
        //     position: "fixed",
        //     width: "100%",
        //     height: 70
        // };
        // const collapsed = this.props.collapsed && !this.props.openDrawer;
        const axaltaMenuInstitute = (
            <DropdownMenu>
                <MenuItem>
                    <Link to="/dashboard/news">
                        {< IntlMessages id = "uiElements.dropdown.buttonDropdown.news" />}
                    </Link>
                </MenuItem>
                <MenuItem>
                    <Link to="/dashboard/marketing">
                        {< IntlMessages id = "uiElements.dropdown.buttonDropdown.marketing" />}
                    </Link>
                </MenuItem>
                <MenuItem>
                    <Link to="/dashboard/corporate/tutorials">
                        {< IntlMessages id = "uiElements.dropdown.buttonDropdown.tutorials" />}
                    </Link>
                </MenuItem>
                <MenuItem>
                    <Link to="/dashboard/corporate/downloads">
                        {< IntlMessages id = "uiElements.dropdown.buttonDropdown.downloads" />}
                    </Link>
                </MenuItem>
            </DropdownMenu>
        );
        const axaltaMenuPromotions = (
            <DropdownMenu>
                <MenuItem>
                    <Link to="/dashboard/architectonic">
                        {< IntlMessages id = "uiElements.dropdown.buttonDropdown.arquitectonic" />}
                    </Link>
                </MenuItem>
                <MenuItem>
                    <Link to="/dashboard/repAuto">
                        {< IntlMessages id = "uiElements.dropdown.buttonDropdown.repAuto" />}
                    </Link>
                </MenuItem>
            </DropdownMenu>
        );

        const axaltaMenuTools = (
            <DropdownMenu>
                <MenuItem>
                    <Link to="/dashboard/storeLoc">
                        {< IntlMessages id = "uiElements.dropdown.buttonDropdown.storeLoc" />}
                    </Link>
                </MenuItem>
                <MenuItem>
                    <Link to="/dashboard/StoreRegister">
                        {< IntlMessages id = "uiElements.dropdown.buttonDropdown.StoreRegister" />}
                    </Link>
                </MenuItem>
                <MenuItem>
                    <Link to="/dashboard/directory">
                        {< IntlMessages id = "uiElements.dropdown.buttonDropdown.directory" />}
                    </Link>
                </MenuItem>
            </DropdownMenu>
        );

        const menuSubmenu = (
            <DropdownMenu>

                <SubMenu
                    id="uiElements.dropdown.buttonDropdown.arquitectonic"
                    title={< IntlMessages id = "uiElements.dropdown.buttonDropdown.arquitectonic" />}>
                    <MenuItem>
                        <Link to="/dashboard/architectonic/colorTools">
                            {< IntlMessages id = "uiElements.dropdown.buttonDropdown.colorTools" />}
                        </Link>
                    </MenuItem>
                    <MenuItem>
                        <Link to="/dashboard/architectonic/downloads">
                            {< IntlMessages id = "uiElements.dropdown.buttonDropdown.downloads" />}
                        </Link>
                    </MenuItem>
                    <MenuItem>
                        <Link to="/dashboard/architectonic/securityPage">
                            {< IntlMessages id = "uiElements.dropdown.buttonDropdown.securityPage" />}
                        </Link>
                    </MenuItem>
                   {/*  <MenuItem>
                        <Link to="/dashboard/architectonic/bulletin">
                            {< IntlMessages id = "uiElements.dropdown.buttonDropdown.bulletin" />}
                        </Link>
                    </MenuItem> */}
                </SubMenu>
                <SubMenu
                    id="uiElements.dropdown.buttonDropdown.floats"
                    title={< IntlMessages id = "uiElements.dropdown.buttonDropdown.floats" />}>
                    <MenuItem>
                        <Link to="/dashboard/fleets/colorTools">
                            {< IntlMessages id = "uiElements.dropdown.buttonDropdown.colorTools" />}
                        </Link>
                    </MenuItem>
                    <MenuItem>
                        <Link to="/dashboard/fleets/downloads">
                            {< IntlMessages id = "uiElements.dropdown.buttonDropdown.downloads" />}
                        </Link>
                    </MenuItem>
                    <MenuItem>
                        <Link to="/dashboard/fleets/securityPage">
                            {< IntlMessages id = "uiElements.dropdown.buttonDropdown.securityPage" />}
                        </Link>
                    </MenuItem>
                    {/* <MenuItem>
                        <Link to="/dashboard/floats/bulletin">
                            {< IntlMessages id = "uiElements.dropdown.buttonDropdown.bulletin" />}
                        </Link>
                    </MenuItem> */}
                </SubMenu>
                {/* <SubMenu
                    id="uiElements.dropdown.buttonDropdown.industry"
                    title={< IntlMessages id = "uiElements.dropdown.buttonDropdown.industry" />}>
                    <MenuItem>
                        <Link to="/dashboard/industry/colorTools">
                            {< IntlMessages id = "uiElements.dropdown.buttonDropdown.colorTools" />}
                        </Link>
                    </MenuItem>
                    <MenuItem>
                        <Link to="/dashboard/industry/downloads">
                            {< IntlMessages id = "uiElements.dropdown.buttonDropdown.downloads" />}
                        </Link>
                    </MenuItem>
                    <MenuItem>
                        <Link to="/dashboard/industry/securityPage">
                            {< IntlMessages id = "uiElements.dropdown.buttonDropdown.securityPage" />}
                        </Link>
                    </MenuItem>
                    <MenuItem>
                        <Link to="/dashboard/industry/bulletin">
                            {< IntlMessages id = "uiElements.dropdown.buttonDropdown.bulletin" />}
                        </Link>
                    </MenuItem>
                </SubMenu> */}
                <SubMenu
                    id="uiElements.dropdown.buttonDropdown.repAuto"
                    title={< IntlMessages id = "uiElements.dropdown.buttonDropdown.repAuto" />}>
                    <MenuItem>
                        <Link to="/dashboard/repAuto/colorTools">
                            {< IntlMessages id = "uiElements.dropdown.buttonDropdown.colorTools" />}
                        </Link>
                    </MenuItem>
                    <MenuItem>
                        <Link to="/dashboard/repAuto/downloads">
                            {< IntlMessages id = "uiElements.dropdown.buttonDropdown.downloads" />}
                        </Link>
                    </MenuItem>
                    <MenuItem>
                        <Link to="/dashboard/repAuto/securityPage">
                            {< IntlMessages id = "uiElements.dropdown.buttonDropdown.securityPage" />}
                        </Link>
                    </MenuItem>
                   {/*  <MenuItem>
                        <Link to="/dashboard/repAuto/bulletin">
                            {< IntlMessages id = "uiElements.dropdown.buttonDropdown.bulletin" />}
                        </Link>
                    </MenuItem> */}
                </SubMenu>
            </DropdownMenu>
        );

        const axaltaMenuAdministrator = (
            <DropdownMenu>
                <MenuItem>
                    <Link to="/dashboard/dashUser">
                        {< IntlMessages id = "uiElements.dropdown.buttonDropdown.dashUser" />}
                    </Link>
                </MenuItem>
                <MenuItem>
                    <Link to="/dashboard/userList">
                        {< IntlMessages id = "uiElements.dropdown.buttonDropdown.userList" />}
                    </Link>
                </MenuItem>
                <MenuItem>
                    <Link to="/dashboard/register">
                        {< IntlMessages id="uiElements.dropdown.buttonDropdown.register" />}
                    </Link>
                </MenuItem>
                <MenuItem>
                    <Link to="/dashboard/banners">
                        {< IntlMessages id="uiElements.dropdown.buttonDropdown.bannerAdmin" />}
                    </Link>
                </MenuItem>
            </DropdownMenu>
        );

        if (isLoggedIn === true) {

            if (view === 'DesktopView') {
                if (admin) {

                    return (
                        <div className="isoRight">
                            {/* <ul className="isoRight">

                                <Dropdown overlay={axaltaMenuInstitute}>
                                    <Link to="/dashboard" style={styles} className="ant-dropdown-link">
                                        {< IntlMessages id = "uiElements.dropdown.buttonDropdown.institute" />}{' '}

                                    </Link>
                                </Dropdown>

                                <Dropdown overlay={axaltaMenuPromotions}>
                                    <Link to="/dashboard" style={styles} className="ant-dropdown-link">
                                        {< IntlMessages id = "uiElements.dropdown.buttonDropdown.promotions" />}{' '}

                                    </Link>
                                </Dropdown>
                                <Dropdown overlay={axaltaMenuTools}>
                                    <Link to="/dashboard" style={styles} className="ant-dropdown-link">
                                        {< IntlMessages id = "uiElements.dropdown.buttonDropdown.tools" />}{' '}

                                    </Link>
                                </Dropdown>

                                <Dropdown overlay={menuSubmenu}>
                                    <Link to="#" style={styles} className="ant-dropdown-link" href="# ">
                                        {< IntlMessages id = "uiElements.dropdown.buttonDropdown.segments" />}{' '}

                                    </Link>
                                </Dropdown>
                                <Link to="/dashboard/contacts" className="ant-dropdown-link">
                                    {< IntlMessages id = "uiElements.dropdown.buttonDropdown.contacts" />}{' '}
                                </Link>
                                <Dropdown overlay={axaltaMenuAdministrator}>
                                    <Link to="/dashboard" style={styles} className="ant-dropdown-link">
                                        {< IntlMessages id = "uiElements.dropdown.buttonDropdown.administrator" />}{' '}

                                    </Link>
                                </Dropdown>

                            </ul> */}
                            <ul className="isoRight">
                                <li
                                    onClick={() => this.setState({selectedItem: "notification"})}
                                    className="isoNotify">
                                    <Whatsapp />
                                </li>
                                <li>
                                    <label><strong style={{color:'#ffffff'}}>Bienvenido,</strong></label><br/>
                                    <label><strong style={{color:'#ffffff'}}>{this.props.usernameTop} {this.props.surnameTop}</strong></label>
                                </li>
                                <li onClick={() => this.setState({selectedItem: "user"})} className="isoUser">
                                    <TopbarUser locale={locale}/>
                                </li>
                            </ul>
                        </div>
                    );
                } else {
                    return (
                        <div className="isoRight">
                            {/* <ul className="isoRight">

                                <Dropdown overlay={axaltaMenuInstitute}>
                                    <Link to="/dashboard" style={styles} className="ant-dropdown-link">
                                        {< IntlMessages id = "uiElements.dropdown.buttonDropdown.institute" />}{' '}

                                    </Link>
                                </Dropdown>

                                <Dropdown overlay={axaltaMenuPromotions}>
                                    <Link to="/dashboard" style={styles} className="ant-dropdown-link">
                                        {< IntlMessages id = "uiElements.dropdown.buttonDropdown.promotions" />}{' '}

                                    </Link>
                                </Dropdown>
                                <Dropdown overlay={axaltaMenuTools}>
                                    <Link to="/dashboard" style={styles} className="ant-dropdown-link">
                                        {< IntlMessages id = "uiElements.dropdown.buttonDropdown.tools" />}{' '}

                                    </Link>
                                </Dropdown>

                                <Dropdown overlay={menuSubmenu}>
                                    <Link to="#" style={styles} className="ant-dropdown-link" href="# ">
                                        {< IntlMessages id = "uiElements.dropdown.buttonDropdown.segments" />}{' '}

                                    </Link>
                                </Dropdown>
                                <Link to="/dashboard/contacts" className="ant-dropdown-link">
                                    {< IntlMessages id = "uiElements.dropdown.buttonDropdown.contacts" />}{' '}
                                </Link>

                            </ul> */}
                            <ul className="isoRight">

                                <li
                                    onClick={() => this.setState({selectedItem: "notification"})}
                                    className="isoNotify">
                                    <Whatsapp />
                                </li>
                                <li>
                                    <label><strong style={{color:'#ffffff'}}>Bienvenido,</strong></label><br/>
                                    <label><strong style={{color:'#ffffff'}}>{this.props.usernameTop} {this.props.surnameTop}</strong></label>
                                </li>
                                <li onClick={() => this.setState({selectedItem: "user"})} className="isoUser">
                                    <TopbarUser locale={locale}/>
                                </li>
                            </ul>
                        </div>
                    );
                }
            } else {
                return (
                    <div className="isoRight">
                        <ul className="isoRight">
                            <li
                                onClick={() => this.setState({selectedItem: "notification"})}
                                className="isoNotify">
                                <Whatsapp />
                                </li>
                            <li>
                                <label><strong style={{color:'#ffffff'}}>Bienvenido,</strong></label><br/>
                                <label><strong style={{color:'#ffffff'}}>{this.props.usernameTop} {this.props.surnameTop}</strong></label>
                            </li>
                            <li onClick={() => this.setState({selectedItem: "user"})} className="isoUser">
                                <TopbarUser locale={locale}/>
                            </li>
                        </ul>
                    </div>
                )
            }
        } else {
            return (
                <div className="isoRight">
                    <ul className="isoRight">
                        <li>
                            {Firebase.isValid && (<FirebaseLogin history={history} login={this.props.login}/>)
}
                        </li>
                    </ul>
                </div>
            );
        }
    }
    displayLogo() {
        const {view} = this.props;
        if (view === 'DesktopView') {
            return (
                <Link to='/dashboard'>
                    <img width="70px" src={LogoAxalta} alt="" style={{paddingRight:"10px"}}/>
                    <img width="120px" src={Logo} alt=""/>
                </Link>
            );
        }
    }

    render() {
        const {toggleCollapsed} = this.props;
        const from = {
            pathname: '/dashboard'
        };
        const collapsed = this.props.collapsed && !this.props.openDrawer;
        const {redirectToReferrer} = this.state;
        const styling = {
            position: "fixed",
            width: "100%",
            height: 70,
            backgroundImage: `url(${background_header})`
        };

        if (redirectToReferrer) {
            return <Redirect to={from}/>;
        }

        return (

            <AxaltaTopbarWrapper>
                <Header
                    style={styling}
                    className={collapsed
                    ? "isomorphicTopbar collapsed"
                    : "isomorphicTopbar"}>
                    <div className="isoLeft">
                        {this.displayLogo()}
                        {window.innerWidth < 1200 &&
                            <button
                                style={{color:'#ffffff'}}
                                className={collapsed
                                ? "triggerBtn menuCollapsed"
                                : "triggerBtn menuOpen"}
                                onClick={toggleCollapsed}/>
                        }
                    </div>

                    {this.displayMenu()}

                </Header>
            </AxaltaTopbarWrapper>
        );
    }
}

export default connect(state => ({
    isLoggedIn: state.Auth.idToken !== null
        ? true
        : false,
    locale: state.LanguageSwitcher.language.locale,
    ...state.App,
    ...state.Auth,
    usernameTop: state.Users.userDB.Username,
    surnameTop: state.Users.userDB.surname
}), {login, clearMenu, isAdmin, toggleCollapsed})(AxaltaTopbar);
