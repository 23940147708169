import * as firebase from 'firebase';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/messaging';
import 'firebase/auth';
import ReduxSagaFirebase from 'redux-saga-firebase';
import {
    firebaseConfig,
    googleConfig
} from '@iso/settings';
import Geocode from 'react-geocode';
Geocode.setApiKey(googleConfig.apiKey);

const valid =
    firebaseConfig && firebaseConfig.apiKey && firebaseConfig.projectId;

const firebaseApp = valid && firebase.initializeApp(firebaseConfig);
const firebaseAuth = valid && firebase.auth;
const firebaseMessaging =
    valid && firebase.messaging.isSupported() && firebase.messaging();

var pushCallBack = (message) => {
    console.log('pushCallback', message)
};

const nameDecodedRegex = /.*\/o(.*\/)(.*)\.(.*)\?.*/g;
const stripLeadingSlashes = /^\/*(.*)/g;
const fileExtensionRegex = /^.*\.([^\.]*)/g;

if (firebaseMessaging) {
    firebaseMessaging.onMessage(payload => {
        const title = payload.notification.title;
        const options = {
            body: payload.notification.body,
            icon: payload.notification.icon
        };
        navigator.serviceWorker.ready.then(registration => {

            registration.showNotification(title, options);
            pushCallBack(payload.notification);
            registration.getNotifications({}).then(notificationsList => {

            });
        });
    });
}

if (firebaseMessaging && 'serviceWorker' in navigator) {
    navigator.serviceWorker
        .register('/593821/firebase-messaging-sw.js', {
            updateViaCache: 'none'
        })
        .then(registration => {
            firebaseMessaging.useServiceWorker(registration);
        });
}

class FirebaseHelper {
    isValid = valid;

    constructor() {
        this.login = this.login.bind(this);
        this.getNewsFeed = this.getNewsFeed.bind(this);
        this.getMarketingNews = this.getMarketingNews.bind(this);
        this.logout = this.logout.bind(this);
        this.signup = this.signup.bind(this);
        this.register = this.register.bind(this);
        this.isAuthenticated = this.isAuthenticated.bind(this);
        this.getUsers = this.getUsers.bind(this);
        this.getAllUsers = this.getAllUsers.bind(this);
        this.getUser = this.getUser.bind(this);
        this.getCurrentUser = this.getCurrentUser.bind(this);
        this.getLocations = this.getLocations.bind(this);
        this.getDeals = this.getDeals.bind(this);
        this.getStoreList = this.getStoreList.bind(this);
        this.getAddressFromLatLng = this.getAddressFromLatLng.bind(this);
        this.subscribeToTopic = this.subscribeToTopic.bind(this);
        this.authStateChanged = this.authStateChanged.bind(this);
        this.isAdminCallback = this.isAdminCallback.bind(this);
        this.isAdmin = this.isAdmin.bind(this);
        this.askForPermissioToReceiveNotifications = this.askForPermissioToReceiveNotifications.bind(this);
        this.onPushMessageReceived = this.onPushMessageReceived.bind(this);
        this.unsubscribeFromTopic = this.unsubscribeFromTopic.bind(this);
        this.getDownloads = this.getDownloads.bind(this);
        this.updateUserDB = this.updateUserDB.bind(this);
        this.sendEmail = this.sendEmail.bind(this);
        this.saveMarketingData = this.saveMarketingData.bind(this);
        this.uploadToStorageAsync = this.uploadToStorageAsync.bind(this);
        this.getDocumentInfo = this.getDocumentInfo.bind(this);
        this.getFileExtension = this.getFileExtension.bind(this);
        this.saveDirectory = this.saveDirectory.bind(this);
        this.updateDirectory = this.updateDirectory.bind(this);
        this.getDirectory = this.getDirectory.bind(this);
        this.deleteDirectory = this.deleteDirectory.bind(this);
        this.saveCatalogue = this.saveCatalogue.bind(this);
        this.updateCatalogue = this.updateCatalogue.bind(this);
        this.getCatalogue = this.getCatalogue.bind(this);
        this.deleteCatalogue = this.deleteCatalogue.bind(this);
        this.saveSection = this.saveSection.bind(this);
        this.updateSection = this.updateSection.bind(this);
        this.getSection = this.getSection.bind(this);
        this.deleteSection = this.deleteSection.bind(this);

        this.database = this.isValid && firebase.firestore();
        this.firestore = this.isValid && firebase.firestore;
        if (this.database) {
            const settings = {
                timestampsInSnapshots: true
            };
            this.database.settings(settings);
        }

        this.rsf =
            this.isValid && new ReduxSagaFirebase(firebaseApp, firebase.firestore());
        this.rsfFirestore = this.isValid && this.rsf.firestore;
    }

    /**
     * User functions
     */
    createBatch = () => {
        return this.database.batch();
    };

    /*     async getCurrentUser_new() {
            const authChannel = eventChannel(emit => {
                const currentUser = firebase
                    .auth()
                    .onAuthStateChanged(currentUser => emit({ currentUser }));
                return currentUser;
            });

            return authChannel;
        } */

    async getCurrentUser() {
        if (!this.isValid) {
            return;
        }
        try {
            firebase.auth().onAuthStateChanged(currentUser => {
                if (currentUser) {
                    return currentUser;
                } else {
                    console.log('Usuario no firmado')
                }
            })
        } catch (error) {
            return error;
        }
    };


    async updateUser(info) {
        if (!this.isValid) {
            return;
        }
        try {
            const user = firebase.auth().currentUser;
            const {
                displayName,
                photoUrl,
                name,
                email
            } = info;
            user
                .updateProfile({
                    displayName: displayName,
                    photoURL: photoUrl,
                    name: name,
                    email: email
                })
                .then(user => {})
                .catch(error => {
                    return error;
                });
        } catch (error) {
            return error;
        }
    }

    async getUsers() {
        if (!this.isValid) {
            return;
        }
        try {
            const data = await firebase.functions().httpsCallable("getDisabledUsers")({});
            return data;
        } catch (error) {
            return error;
        }
    }

    async getAllUsers() {
        if (!this.isValid) {
            return;
        }
        try {
            const role = "all";
            const status = 1;
            const data = await firebase.functions().httpsCallable("getUsers")({
                role,
                status
            });
            return data;
        } catch (error) {
            return error;
        }
    }

    async getUser(uid) {
        if (!this.isValid) {
            return;
        }
        try {

            const data = await firebase.functions().httpsCallable("getUserData")(uid);

            return data;
        } catch (error) {
            console.log(error);
            return error;
        }
    }
    async signup(info) {
        if (!this.isValid) {
            return;
        }
        try {
            const userCredential = await firebaseAuth().createUserWithEmailAndPassword(
                info.email,
                info.password
            );
            const {
                user
            } = userCredential;
            const id = user ? user.uid : undefined;
            const doc = this.database.collection("users").doc(id);
            await doc.set({
                role: "user",
                status: 0,
                email: info.email === undefined ? '' : info.email,
                Username: info.Username === undefined ? '' : info.Username,
                surname: info.surname === undefined ? '' : info.surname,
                sapCode: info.sapCode === undefined ? '' : info.sapCode,
                company: info.company === undefined ? '' : info.company,
                phoneNumber: info.phoneNumber === undefined ? '' : info.phoneNumber,
                phoneMobile: info.phoneMobile === undefined ? '' : info.phoneMobile,
                address1: info.address1 === undefined ? '' : info.address1,
                address2: info.address2 === undefined ? '' : info.address2,
                address3: info.apt === undefined ? '' : info.apt,
                neighborhood: info.neighborhood === undefined ? '' : info.neighborhood,
                zipCode: info.zipCode === undefined ? '' : info.zipCode,
                city: info.city === undefined ? '' : info.city,
                state1: info.state1 === undefined ? '' : info.state1,
                repoAuto: info.segment.indexOf('repoAuto') >= 0 ? true : false,
                industry: info.segment.indexOf('industry') >= 0 ? true : false,
                arquitectonic: info.segment.indexOf('arquitectonic') >= 0 ? true : false,
                float: info.segment.indexOf('floats') >= 0 ? true : false,
                executiveName: info.executiveName === undefined ? '' : info.executiveName,
                branchNumber: info.branchNumber === undefined ? '' : info.branchNumber
            });
        } catch (error) {
            console.error(error);
            switch (error.code) {
                case 'auth/email-already-in-use':
                    error = "Otro usuario ya está utilizando el correo electrónico proporcionado. Cada usuario debe tener un correo electrónico único.";
                    break;
                default:
                    error = 'Hubo un problema al intentar crear el usuario, intente de nuevo mas tarde';
                    break;
            }
            return {error};
        }
    }

    async updateImage(url, uid) {
        if (!this.isValid) {
            return;
        }
        try {
            const user = await firebase.functions().httpsCallable("getUserData")({
                'userId': uid
            });
            console.debug(user);
            const doc = await this.database.collection("users").doc(uid);
            // const photoURL = `${uid}/photoURL`;
            await doc.update({
                photoURL: url
            });
        } catch (error) {
            console.log(error)
            return error;
        }
    }

    async register(info) {
        if (!this.isValid) {
            return;
        }
        try {
            const { Username, surname, role = "axaltauser", email, password } = info;
            const userCredential = await firebase.functions().httpsCallable("createUser")({
                email,
                password
            })
            const {
                data: { user, error }
            } = userCredential;
            if (error) {
                const { errorInfo } = error;
                switch (errorInfo.code) {
                    case 'auth/email-already-exists':
                        throw new Error("Otro usuario ya está utilizando el correo electrónico proporcionado. Cada usuario debe tener un correo electrónico único.")
                    default:
                        throw new Error('Hubo un problema al intentar crear el usuario, intente de nuevo mas tarde');

                }
            }
            const id = user ? user.uid : undefined;
            const doc = this.database.collection("users").doc(id);
            await doc.set({
                role: role,
                status: 1,
                Username: Username,
                surname: surname,
                email: email
            });
            firebaseAuth().sendPasswordResetEmail(email);

            return { user };
        } catch (error) {
            return { error };
        }
    }

    async updateUserDB(data, uid) {
        if (!this.isValid) {
            return;
        }
        try {
            const doc = this.database.collection("users").doc(uid);
            await doc.update(data);
            const result = await firebase.functions().httpsCallable("getUserData")({ userId: uid });
            return result;
        } catch (error) {
            console.log(error)
            return error;
        }
    }

    async isAdminCallback(callback) {
        await firebase.auth().onAuthStateChanged(async user => {
            const result = await user.getIdTokenResult().then(idTokenResult => {
                return idTokenResult.claims.admin;
            });
            callback(result);
        });
    }

    authStateChanged(callback) {
        firebase.auth().onAuthStateChanged(user => {
            callback(user ? user : null);
        })
    }

    onPushMessageReceived(callback) {
        pushCallBack = callback;
    }


    async isAdmin(uid) {
        try {
            const result = await this.getUser({"userId":uid}).then((user) => {
                const role  = user.data.role;
                return role === "administrador";
            })
            // const result = await firebase.auth().currentUser.getIdTokenResult().then((idTokenResult) => {
            //     console.log(idTokenResult);
            //     const resultClaims = idTokenResult.claims.admin;
            //     return resultClaims;
            // });
            return result;
        } catch (error) {
            console.log(error)
            return error;

        }
    }

    async login(provider, info) {
        if (!this.isValid) {
            return;
        }
        try {
            switch (provider) {
                case this.EMAIL:
                    const providers = await firebase.auth().fetchSignInMethodsForEmail(info.email);
                    if (providers.length === 0) {
                        const sendMailResult = await firebase.auth().sendPasswordResetEmail(info.email).catch(error => {
                            return 'No existe';
                        }).then(() => {
                            return "OK";
                        });
                        if (sendMailResult === 'OK') {
                            return 'Password reset has sent';
                        } else {
                            throw new Error('no se encuentra el email');
                        }
                    } else {
                        const result = await firebaseAuth().signInWithEmailAndPassword(
                            info.email,
                            info.password
                        );
                        return result;
                    }
                default:
                    firebase().UserInfo(info.displayname);
            }
        } catch (error) {
            return error;
        }
    }
    logout() {
        return firebaseAuth().signOut();
    }

    isAuthenticated() {
        firebaseAuth().onAuthStateChanged(user => {
            return user ? true : false;
        });
    }
    resetPassword(email) {
        return firebaseAuth().sendPasswordResetEmail(email);
    }
    createNewRef() {
        return firebase
            .database()
            .ref()
            .push().key;
    }
    processFireStoreCollection(snapshot) {
        let data = {};
        snapshot.forEach(doc => {
            data = {
                ...data,
                [doc.id]: doc.data()
            };
        });
        return data;
    }

    //News functions
    /* 
        async getNewsfeed() {
            if (!this.isValid) {
                return {};
            }

            try {
                const data = await firebase.functions().httpsCallable("newsFeed")({
                    page: 1,
                    limit: 20
                });
                return data;
            } catch (error) {
                throw error;
            }
        } */
    async getMarketingNews() {
        if (!this.isValid) {
            return {};
        }

        try {
            const data = await firebase.functions().httpsCallable("mktFeed")({
                page: 1,
                limit: 20
            });
            return data;
        } catch (error) {
            throw error;
        }
    }

    arrayContains = (arrhaystack, needle) => {
        return arrhaystack.indexOf(needle) > -1;
    };

    getLocationFromObject = (locObj, id) => {
        const {
            address_components,
            formatted_address,
            geometry
        } = locObj;
        const location = {
            ID: id,
            marker: {
                borderStyle: "double",
                borderColor: "red",
                fontFamily: "ionicons",
                iconClass: "ion-android-pin"
            }
        };
        var address = {};

        address_components.forEach(addComp => {
            const {
                types,
                long_name
            } = addComp;
            if (this.arrayContains(types, "street_number")) {
                address.streetNumber = long_name;
            } else if (this.arrayContains(types, "route")) {
                address.street = long_name;
            } else if (this.arrayContains(types, "postal_code")) {
                address.zipCode = long_name;
            } else if (this.arrayContains(types, "political")) {
                if (this.arrayContains(types, "sublocality")) {
                    address.neighborhood = long_name;
                } else if (this.arrayContains(types, "locality")) {
                    address.city = long_name;
                } else if (this.arrayContains(types, "administrative_area_level_1")) {
                    address.state = long_name;
                } else if (this.arrayContains(types, "country")) {
                    address.country = long_name;
                }
            }
        });
        location.address = address;
        location.title = formatted_address;
        location.location = address.city + ", " + address.state;
        location.lat = geometry.location.lat;
        location.lng = geometry.location.lng;
        return location;
    };
    //Maps functions
    async getLocations(values) {
        if (!this.isValid) {
            return {};
        }
        try {
            const {
                address
            } = values;
            const locations = [];
            await Geocode.fromAddress(address)
                .then(({
                    results,
                    status
                }) => {
                    //                    const { results, status } = geoLocations;
                    if (status === "OK") {
                        results.forEach((locObj, index) => {
                            const addTypes = locObj.types;
                            if (
                                this.arrayContains(addTypes, "street_address") ||
                                this.arrayContains(addTypes, "establishment") ||
                                this.arrayContains(addTypes, "subpremise") ||
                                this.arrayContains(addTypes, "route")
                            ) {
                                const location = this.getLocationFromObject(locObj, index + 1);
                                if (
                                    location.address.country === "México" ||
                                    location.address.country === "Mexico"
                                ) {
                                    locations.push(location);
                                }
                            }
                        });
                    }
                })
                .catch(error => {
                    throw error;
                });
            return locations;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async getAddressFromLatLng(lat, lng) {
        if (!this.isValid) {
            return {};
        }
        try {
            const locations = [];
            await Geocode.fromLatLng(lat, lng)
                .then(({
                    results,
                    status
                }) => {
                    if (status === "OK") {
                        results.forEach((locObj, index) => {
                            const addTypes = locObj.types;
                            if (this.arrayContains(addTypes, "street_address")) {
                                const location = this.getLocationFromObject(locObj, index + 1);
                                if (
                                    location.address.country === "México" ||
                                    location.address.country === "Mexico"
                                ) {
                                    locations.push(location);
                                }
                            }
                        });
                    }
                })
                .catch(error => {
                    throw error;
                });
            return locations;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async getStoreList() {
            if (!this.isValid) {
                return {};
            }
            try {
                const storeList = await firebase.functions().httpsCallable("getStores")({
                    page: 1,
                    limit: 30
                });

                return storeList;
            } catch (error) {
                console.log(error);
                throw error;
            }
        }
        /**
         * STORE DATA Location
         *
         */

    async saveStoreData(info) {
        if (!this.isValid) {
            return;
        }
        try {
            const doc = this.database.collection("locations").doc();
            const result = await doc.set({
                address: {
                    apt: info.address.apt ? info.address.apt : "",
                    city: info.address.city,
                    country: info.address.country,
                    neighborhood: info.address.neighborhood,
                    state: info.address.state,
                    street: info.address.street,
                    streetNumber: info.address.streetNumber,
                    zipCode: info.address.zipCode
                },
                location: info.location,
                storeData: {
                    contactNumber: info.storeData.contactNumber,
                    storeName: info.storeData.storeName,
                    mobilePhone: info.storeData.mobilePhone,
                    company: info.storeData.company,
                    sapCode: info.storeData.sapCode,
                    storeMail: info.storeData.storeMail,
                    comments: info.storeData.comments ? info.storeData.comments : "",
                    respName: info.storeData.respName
                },
                repoAuto: info.repoAuto,
                industry: info.industry,
                architectonic: info.architectonic,
                floats: info.floats
            });
            return result;
        } catch (error) {
            console.log(error);
            return error;
        }
    }

    async askForPermissioToReceiveNotifications() {
        if (!this.isValid || !firebaseMessaging) {
            return;
        }
        try {
            //            const messaging = firebase.messaging();
            await firebaseMessaging.requestPermission();
            const token = await firebaseMessaging.getToken();
            return token;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    // SAVE NEW
    async saveNewData(info) {
        if (!this.isValid) {
            return;
        }
        try {

            const doc = this.database.collection('newsFeed/feedTables/News').doc();
            const result = await doc.set({
                created: firebase.firestore.FieldValue.serverTimestamp(),
                imageUrl: info.imageUrl,
                text: info.text,
                textHtml: info.textHtml,
                title: info.title,
                extraImages: info.extraImages,
            });
            console.debug(result);
            return doc.id;
        } catch (error) {
            console.log(error);
            return error;
        }

    }

    async saveMarketingData(info) {
        if (!this.isValid) {
            return;
        }
        try {
            const base = this.database.collection('newsFeed').doc('feedTables');
            const doc = base.collection('Marketing').doc();
            const result = await doc.set({
                created: firebase.firestore.FieldValue.serverTimestamp(),
                imageUrl: info.imageUrl,
                text: info.text,
                textHtml: info.textHtml,
                title: info.title,
                extraimages: info.extraImages
            });
            return result;
        } catch (error) {
            console.log(error);
            return error;
        }
    }

    async subscribeToTopic(topic) {
        if (!this.isValid || !firebaseMessaging) {
            return;
        }

        try {
            const token = await firebaseMessaging.getToken();
            const subscriptionResult = await firebase
                .functions()
                .httpsCallable('subscribeToTopic')({
                    token: token,
                    topic: topic
                });
            console.debug(subscriptionResult);
            return 'OK';
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    async unsubscribeFromTopic(topic) {
        if (!this.isValid || !firebaseMessaging) {
            return;
        }
        try {
            const token = await firebaseMessaging.getToken();
            const unsubscriptionResult = await firebase
                .functions()
                .httpsCallable('unsubscribeFromTopic')({
                    token: token,
                    topic: topic
                });
            console.debug(unsubscriptionResult);
            return 'OK';
        } catch (error) {
            console.log(error);
        }
    }


    //QR functions

    async getQRCode({
        uid
    }) {
        if (!this.isValid) {
            return {};
        }
        try {
            const qrRequestedSnapshot = await firebase
                .functions()
                .httpsCallable("generateQrToken")({
                    userId: uid
                });
            return qrRequestedSnapshot.data;
        } catch (error) {
            throw error;
        }
    }

    async getDeals(type) {
        if (!this.isValid) {
            return;
        }

        try {
            const data = await firebase.functions().httpsCallable("promFeed")({
                page: "1",
                limit: 20,
                type: type
            });
            return data;
        } catch (error) {
            throw error;
        }
    }

    async getDownloads(segment, section) {
        if (!this.isValid || !segment || !section) {
            return [];
        }
        try {
            const result = [];
            const query = this.database.collection('/downloads/' + segment + '/' + section);
            const queryResponse = await query.orderBy('created', 'desc').get();
            const queryResult = await Promise.resolve(queryResponse);

            queryResult.forEach(doc => {
                const document = Object.assign({
                    id: doc.id
                }, doc.data());
                result.push(document);
            });

            return result;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async getNewsFeed(type, subType = '') {
        if (!this.isValid || !type) {
            return [];
        }
        try {
            const result = [];
            const query = this.database.collection('/newsFeed/feedTables/' + type);
            const subQuery = subType.trim().length>0?query.where('type', '==', subType.trim()):query;
            const queryResponse = await subQuery.orderBy('created', 'desc').get();
            const queryResult = await Promise.resolve(queryResponse);

            queryResult.forEach(doc => {
                const document = Object.assign({
                    id: doc.id
                }, doc.data());
                result.push(document);
            });

            return result;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async uploadToStorage(storagePath, file) {
        const storageRef = firebase.storage().ref(storagePath);
        const fileRef = storageRef.child(file.name);
        let result = await fileRef.put(file)
            .then(snap => {
                return snap;
            })
            .catch(err => err);

        return result;
    }
    uploadToStorageAsync(storagePath, file, fileName='') {
        const storageRef = firebase.storage().ref(storagePath);
        var fileRef;
        if (fileName.length===0){
            fileRef = storageRef.child(file.name);
        } else {
            fileRef = storageRef.child(fileName);
        }
        return fileRef.put(file);
    }

    getFileExtension = fileName => {
        return fileName.replace(fileExtensionRegex, '$1');
    };

    getDocumentInfo = documentUrl => {
        const decodedDocument = decodeURIComponent(documentUrl);
        let fileLocation = '';
        let fileName = '';
        let fileExtension = '';
        try {
            const firstLocation = decodedDocument.replace(nameDecodedRegex, '$1');
            fileLocation = firstLocation.replace(stripLeadingSlashes, '$1');

            fileExtension = decodedDocument
                .replace(nameDecodedRegex, '$3')
                .trim();
            fileName = decodedDocument.replace(nameDecodedRegex, '$2') + '.' + fileExtension;
            fileExtension = fileExtension.toLowerCase();
            return { fileName, fileExtension, fileLocation };
        } catch (error) {
            console.log(error);
            throw error;
        }
    };

    async deleteToStorage(storagePath, filePath) {
        const storageRef = firebase.storage().ref(storagePath);
        const fileRef = storageRef.child(filePath);
        let result = await fileRef.delete().then(() => {
            return true;
        }).catch(error => {
            console.log(error);
            return false;
        });
        return result;
    }

    async saveDeals(info) {
        if (!this.isValid) {
            return;
        }
        try {
            const base = this.database.collection('newsFeed').doc('feedTables');
            const doc = base.collection('Deals').doc();
            const result = await doc.set({
                created: firebase.firestore.FieldValue.serverTimestamp(),
                downloads: info.downloads,
                imageUrl: info.imageUrl,
                text: info.text,
                textHtml: info.text,
                subtitle: info.subtitle,
                title: info.title,
                type: info.type
            });
            return result;
        } catch (error) {
            console.log(error);
            return error;
        }
    }

    async saveDownloads(info) {
        const {
            segment,
            section,
            downloadUrl,
            id,
            title,
            category,
            sectionVal
        } = info;

        if (!this.isValid) {
            return;
        }
        try {
            const base = this.database.collection('downloads').doc(segment).collection(section);
            const doc = !id?base.doc():base.doc(id);
            var downloadDoc = {
                created: firebase.firestore.FieldValue.serverTimestamp(),
                downloadUrl,
                category,
                section: sectionVal
            };
            if (title){
                downloadDoc.title=title
            };
            const result = await doc.set(downloadDoc);
            return result;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async sendEmail(info) {
        if (!this.isValid) {
            return;
        }
        try {
            const mensaje = await firebase.functions().httpsCallable("sendContactEmail")({
                ...info
            });
            console.debug(mensaje);
        } catch (error) {
            console.log(error);
            return { error };
        }
    }

    // DIRECTORY
    async saveDirectory(info) {
        if (!this.isValid) {
            return;
        }
        try {
            const doc = this.database.collection('directory').doc();
            const result = await doc.set(info);
            return result;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async getDirectory() {
        if (!this.isValid) {
            return [];
        }
        try {
            const result = [];
            const query = this.database.collection('directory').get();
            const queryResult = await Promise.resolve(query);

            queryResult.forEach(doc => {
                const document = Object.assign({
                    id: doc.id
                }, doc.data());
                result.push(document);
            });

            return result;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async updateDirectory(info) {

        if (!this.isValid) {
            return;
        }
        try {
            const base = this.database.collection('directory').doc();
            const doc = base.doc();
            const result = await doc.set(info);
            return result;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async deleteDirectory(doc) {
        if (!this.isValid) {
            return [];
        }
        try {
            const query = this.database.collection('directory').doc(doc).delete();
            const result = await Promise.resolve(query);

            return result;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    // CATALOGUE
    async saveCatalogue(info) {
        if (!this.isValid) {
            return;
        }
        try {
            const doc = this.database.collection('catalogue').doc();
            const result = await doc.set(info);
            return result;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async getCatalogue() {
        if (!this.isValid) {
            return [];
        }
        try {
            const result = [];
            const query = this.database.collection('catalogue').get();
            const queryResult = await Promise.resolve(query);

            queryResult.forEach(doc => {
                const document = Object.assign({
                    id: doc.id
                }, doc.data());
                result.push(document);
            });

            return result;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async updateCatalogue(info) {

        if (!this.isValid) {
            return;
        }
        try {
            const doc = this.database.collection('catalogue').doc(info.id);
            const result = await doc.update(info);
            return result;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async deleteCatalogue(doc) {
        if (!this.isValid) {
            return [];
        }
        try {
            const query = this.database.collection('catalogue').doc(doc).delete();
            const result = await Promise.resolve(query);

            return result;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    // CATALOGUE -> SECTION
    async saveSection(info) {
        if (!this.isValid) {
            return;
        }
        try {
            const doc = this.database.collection('section').doc();
            const result = await doc.set(info);
            return result;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async getSection() {
        if (!this.isValid) {
            return [];
        }
        try {
            const result = [];
            const query = this.database.collection('section').get();
            const queryResult = await Promise.resolve(query);

            queryResult.forEach(doc => {
                const document = Object.assign({
                    id: doc.id
                }, doc.data());
                result.push(document);
            });

            return result;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async updateSection(info) {

        if (!this.isValid) {
            return;
        }
        try {
            const doc = this.database.collection('section').doc(info.id);
            const result = await doc.update(info);
            return result;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async deleteSection(doc) {
        if (!this.isValid) {
            return [];
        }
        try {
            const query = this.database.collection('section').doc(doc).delete();
            const result = await Promise.resolve(query);

            return result;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }
}

export default new FirebaseHelper();