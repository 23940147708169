import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import Firebase from '../../helpers/firebase/index';
import { notification } from '@iso/components/index';

const { saveCatalogue, getCatalogue, updateCatalogue, deleteCatalogue } = Firebase;

function* loadCatalogueAsc() {
	try {
		const catalogue = yield call(
			async () => await getCatalogue()
		);
		const result = [];
		catalogue.forEach(value => {
			result.push({
				key: value.id,
				...value
			})
		})
		yield put(actions.loadCatalogueSuccess(result));
	} catch (error) {
		console.log(error);
		yield put(actions.loadCatalogueFailed(error));
	}
}

function* deleteCatalogueAsc({ payload: { idCat } }) {
	try {
		yield call( async () => await deleteCatalogue(idCat) );
		notification('success', 'Registro eliminado correctamente');
		yield put(actions.loadCatalogue());
	} catch (error) {
		console.log(error);
		yield put(actions.deleteCatalogueFailed(error));
		notification('error', 'Hubo un error al eliminar el catalogue');
	}
}

function* saveCatalogueAsc({ payload: { catalogue } }) {
	try {
		yield call(async () => await saveCatalogue(catalogue) );
		notification('success', 'Registro guardado correctamente');
		yield put(actions.loadCatalogue());
	} catch (error) {
		console.log(error);
		yield put(actions.saveCatalogueFailed(error));
		notification('error', 'Hubo un error al guardar el Registro');
	}
}

function* updateCatalogueAsc({ payload: { catalogue } }) {
	try {
		yield call(async () => await updateCatalogue(catalogue));
		yield put(actions.updateCatalogueSuccess());
		notification('success', 'Registro actualizado correctamente');
		yield put(actions.loadCatalogue());
	} catch (error) {
		console.log(error);
		yield put(actions.saveCatalogueFailed(error));
		notification('error', 'Hubo un error al guardar el Registro');
	}
}

export default function* rootSaga() {
	yield all([
		takeEvery(actions.LOAD_CATALOGUE, loadCatalogueAsc),
		takeEvery(actions.UPDATE_CATALOGUE, updateCatalogueAsc),
		takeEvery(actions.DELETE_CATALOGUE, deleteCatalogueAsc),
		takeEvery(actions.SAVE_CATALOGUE, saveCatalogueAsc)
	]);
}
