const DOCUMENT = 'NEWS_FEED';

const actions = {
	LOAD_DOCUMENTS: DOCUMENT + 'LOAD_DOCUMENTS',
	LOAD_DOCUMENTS_SUCCESS: DOCUMENT + 'LOAD_DOCUMENTS_SUCCESS',
	LOAD_DOCUMENTS_FAILED: DOCUMENT + 'LOAD_DOCUMENTS_FAILED',

	DELETE_DOCUMENT: DOCUMENT + 'DELETE_DOCUMENT',
	DELETE_DOCUMENT_SUCCESS: DOCUMENT + 'DELETE_DOCUMENT_SUCCESS',
	DELETE_DOCUMENT_FAILED: DOCUMENT + 'DELETE_DOCUMENT_FAILED',

	EDIT_DOCUMENT: DOCUMENT + 'EDIT_DOCUMENT',
	EDIT_DOCUMENT_CANCEL: DOCUMENT + 'EDIT_DOCUMENT_CANCEL',
	SAVE_DOCUMENT: DOCUMENT + 'SAVE_DOCUMENT',
	SAVE_DOCUMENT_SUCCESS: DOCUMENT + 'SAVE_DOCUMENT_SUCCESS',
	SAVE_DOCUMENT_FAILED: DOCUMENT + 'SAVE_DOCUMENT_FAILED',

	FILTER_DOCUMENTS: DOCUMENT + 'FILTER_DOCUMENTS',
	FILTER_DOCUMENTS_SUCCESS: DOCUMENT + 'FILTER_DOCUMENTS_SUCCESS',
	FILTER_DOCUMENTS_FAILED: DOCUMENT + 'FILTER_DOCUMENTS_FAILED',
	CLEAR_FILTERS: DOCUMENT + 'CLEAR_FILTERS',

	SET_TYPE: DOCUMENT + 'SET_TYPE',

	setType: type => ({
		type: actions.SET_TYPE,
		payload: { type }
	}),
	loadDocuments: (type, subType = '') => ({
		type: actions.LOAD_DOCUMENTS,
		payload: { type, subType }
	}),
	loadDocumentsSuccess: data => ({
		type: actions.LOAD_DOCUMENTS_SUCCESS,
		payload: { documents: data }
	}),
	loadDocumentsFailed: error => ({
		type: actions.LOAD_DOCUMENTS_FAILED,
		payload: { error }
	}),
	deleteDocument: (type, documentId) => ({
		type: actions.DELETE_DOCUMENT,
		payload: { type, documentId }
	}),
	deleteDocumentSuccess: () => ({
		type: actions.DELETE_DOCUMENT_SUCCESS
	}),
	deleteDocumentFailed: error => ({
		type: actions.DELETE_DOCUMENT_FAILED,
		payload: { error }
	}),
	saveDocument: (type, data) => ({
		type: actions.SAVE_DOCUMENT,
		payload: { type, data }
	}),
	saveDocumentSuccess: () => ({
		type: actions.SAVE_DOCUMENT_SUCCESS
	}),
	saveDocumentFailed: error => ({
		type: actions.SAVE_DOCUMENT_FAILED,
		payload: { error }
	}),
	editDocument: () => ({
		type: actions.EDIT_DOCUMENT
	}),
	editDocumentCancel: () => ({
		type: actions.EDIT_DOCUMENT_CANCEL
	}),
	filterDocuments: searchText => {
		return {
			type: actions.FILTER_DOCUMENTS,
			payload: { searchText }
		};
	},
	filterDocumentsSuccess: data => {
		return {
			type: actions.FILTER_DOCUMENTS_SUCCESS,
			payload: { documents: data }
		};
	},
	filterDocumentsFailed: error => {
		return {
			type: actions.FILTER_DOCUMENTS_FAILED,
			payload: { error: error }
		};
	},
	clearFilters: () => {
		return {
			type: actions.CLEAR_FILTERS
		};
	}
};

export default actions;
