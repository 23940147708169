const DOCUMENT = 'GEOLOCATION_';

const actions = {
    LOAD_FROM_FIRESTORE: DOCUMENT + 'LOAD_FROM_FIRESTORE',
    LOAD_FROM_FIRESTORE_SUCCESS: DOCUMENT + 'LOAD_FROM_FIRESTORE_SUCCESS',
    LOAD_FROM_FIRESTORE_ERROR: DOCUMENT + 'LOAD_FROM_FIRESTORE_ERROR',

    SAVE_INTO_FIRESTORE: DOCUMENT + 'SAVE_INTO_FIRESTORE',
    SAVE_INTO_FIRESTORE_ERROR: DOCUMENT + 'SAVE_INTO_FIRESTORE_ERROR',

    RESET_FIRESTORE_DOCUMENTS: DOCUMENT + 'RESET_FIRESTORE_DOCUMENTS',
    RESET_FIRESTORE_DOCUMENTS_ERROR: DOCUMENT + 'RESET_FIRESTORE_DOCUMENTS_ERROR',

    TOGGLE_FIRESTORE_HANDLE_MODAL: DOCUMENT + 'TOGGLE_FIRESTORE_HANDLE_MODAL',
    FIRESTORE_UPDATE: DOCUMENT + 'FIRESTORE_UPDATE',

    LOAD_FROM_GOOGLE_MAPS: DOCUMENT + 'LOAD_FROM_GOOGLE_MAPS',
    LOAD_FROM_GOOGLE_MAPS_SUCCESS: DOCUMENT + 'LOAD_FROM_GOOGLE_MAPS_SUCCESS',
    LOAD_FROM_GOOGLE_MAPS_ERROR: DOCUMENT + 'LOAD_FROM_GOOGLE_MAPS_ERROR',

    SELECT_FROM_GOOGLE_MAPS: DOCUMENT + 'SELECT_FROM_GOOGLE_MAPS',

    CHANGE_FIELD_VALUES: DOCUMENT + 'CHANGE_FIELD_VALUES',

    DRAG_MAP_MARKER: DOCUMENT + 'DRAG_MAP_MARKER',

    GET_LOCATION_FROM_LATLNG: DOCUMENT + 'GET_LOCATION_FROM_LATLNG',

    loadFromGoogleMaps: values => {
        return { type: actions.LOAD_FROM_GOOGLE_MAPS,
                    payload: values
        };
    },

    loadFromGoogleMapsSuccess: data => {
        return { 
            type: actions.LOAD_FROM_GOOGLE_MAPS_SUCCESS,
            payload: { data }
        };
    },

    loadFromGoogleMapsError: error => {
        return { 
            type: actions.LOAD_FROM_GOOGLE_MAPS_ERROR,
            payload: { error }
        };
    },

    selectFromGoogleMaps: data => {
        return {
            type: actions.SELECT_FROM_GOOGLE_MAPS,
            payload: { data }
        }
    },

    loadFromFireStore: () => {
        return { type: actions.LOAD_FROM_FIRESTORE };
    },

    loadFromFireStoreSuccess: data => ({

        type: actions.LOAD_FROM_FIRESTORE_SUCCESS,
        payload: { data },

    }),

    loadFromFireStoreError: error => ({
        type: actions.LOAD_FROM_FIRESTORE_ERROR,
        payload: { error },
    }),

    saveIntoFireStore: (data, actionName = 'insert') => ({
        type: actions.SAVE_INTO_FIRESTORE,
        payload: { data, actionName },
    }),

    toggleModal: (data = null) => ({
        type: actions.TOGGLE_FIRESTORE_HANDLE_MODAL,
        payload: { data },
    }),

    update: data => ({
        type: actions.FIRESTORE_UPDATE,
        payload: { data },
    }),

    saveIntoFireStoreError: error => ({
        type: actions.SAVE_INTO_FIRESTORE_ERROR,
        payload: { error },
    }),

    changeFieldValues: (fieldName, value) => ({
            type: actions.CHANGE_FIELD_VALUES,
            payload: {fieldName, value}

    }),

    dragMapMarker: (item) => ({
        type: actions.DRAG_MAP_MARKER,
        payload: { item }
    }),

    getLocationFromLatLng: ( latlng ) => ({
        type: actions.GET_LOCATION_FROM_LATLNG,
        payload: { latlng }
    }),

};




export default actions;