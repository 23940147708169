export const options = [
	/* Axalta Custom Begin */
	{
		key: 'administrator',
		label: 'sidebar.administrator',
		leftIcon: 'ion-android-checkbox-outline',
		children: [
			{
				key: 'dashUser',
				label: 'sidebar.dashUser',
				withoutDashboard: false
			},
			{
				key: 'userList',
				label: 'sidebar.userList',
				withoutDashboard: false
			},
			{
				key: 'register',
				label: 'sidebar.register',
				withoutDashboard: false
			},
			{
				key: 'banners',
				label: 'sidebar.bannerAdmin',
				withoutDashboard: false
			},
			{
				key: 'catalogues',
				label: 'sidebar.catalogues',
				withoutDashboard: false
			}
		]
	},
	{
		key: 'institute',
		label: 'sidebar.institute',
		leftIcon: 'ion-briefcase',
		children: [
			{
				key: 'news',
				label: 'sidebar.news',
				withoutDashboard: false
			},
			{
				key: 'marketing',
				label: 'sidebar.marketing',
				withoutDashboard: false
			},
			{
				key: 'corporate/tutorials',
				label: 'sidebar.tutorials',
				withoutDashboard: false
			},
			{
				key: 'corporate/downloads',
				label: 'sidebar.downloads',
				withoutDashboard: false
			}
		]
	},
	{
		key: 'promotions',
		label: 'sidebar.promotions',
		leftIcon: 'ion-arrow-graph-up-right',
		children: [
			{
				key: 'deals/architectonic',
				label: 'sidebar.arquitectonic',
				withoutDashboard: false
			},
			{
				key: 'deals/automotive',
				label: 'sidebar.repAuto',
				withoutDashboard: false
			}
		]
	},
	{
		key: 'Tools',
		label: 'sidebar.tools',
		leftIcon: 'ion-hammer',
		children: [
			{
				key: 'storeLoc',
				label: 'sidebar.storeLoc',
				withoutDashboard: false
			},
			{
				key: 'StoreRegister',
				label: 'sidebar.StoreRegister',
				withoutDashboard: false
			},
			{
				key: 'directory',
				label: 'sidebar.directory',
				withoutDashboard: false
			}
		]
	},

	// {
	// 	key: 'contacts',
	// 	label: 'sidebar.contacts',
	// 	leftIcon: 'ion-chatbubbles'
	// }
	/* Axalta Custom End */
];
export const optNoAdmin = [
	/* Axalta Custom Begin */

	{
		key: 'institute',
		label: 'sidebar.institute',
		leftIcon: 'ion-briefcase',
		children: [
			{
				key: 'news',
				label: 'sidebar.news',
				withoutDashboard: false
			},
			{
				key: 'marketing',
				label: 'sidebar.marketing',
				withoutDashboard: false
			},
			{
				key: 'corporate/tutorials',
				label: 'sidebar.tutorials',
				withoutDashboard: false
			},
			{
				key: 'corporate/downloads',
				label: 'sidebar.downloads',
				withoutDashboard: false
			}
		]
	},
	{
		key: 'promotions',
		label: 'sidebar.promotions',
		leftIcon: 'ion-arrow-graph-up-right',
		children: [
			{
				key: 'deals/architectonic',
				label: 'sidebar.arquitectonic',
				withoutDashboard: false
			},
			{
				key: 'deals/automotive',
				label: 'sidebar.repAuto',
				withoutDashboard: false
			}
		]
	},
	{
		key: 'Tools',
		label: 'sidebar.tools',
		leftIcon: 'ion-hammer',
		children: [
			{
				key: 'storeLoc',
				label: 'sidebar.storeLoc',
				withoutDashboard: false
			},
			{
				key: 'StoreRegister',
				label: 'sidebar.StoreRegister',
				withoutDashboard: false
			},
			{
				key: 'directory',
				label: 'sidebar.directory',
				withoutDashboard: false
			}
		]
	},
	// {
	// 	key: '',
	// 	label: 'sidebar.',
	// 	leftIcon: 'ion-chatbubbles'
	// }
	/* Axalta Custom End */
];
