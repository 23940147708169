import actions from './actions';

const initState = {
	section: []
};

export default function reducer(
	state = initState,
	{ type, payload, newRecord }
) {
	switch (type) {
		case actions.LOAD_SECTION:
			return {
				...state
			}
		case actions.LOAD_SECTION_SUCCESS:
			return {
				...state,
				section: payload.section
			}
		case actions.LOAD_SECTION_FAILED:
			return {
				...state,
				error: payload.error
			}

		case actions.DELETE_SECTION:
			return {
				...state,
			}
		case actions.DELETE_SECTION_SUCCESS:
			return {
				...state,
				section: payload.section
			}
		case actions.DELETE_SECTION_FAILED:
			return {
				...state,
				error: payload.error
			}

		case actions.SAVE_SECTION:
			return {
				...state
			}
		case actions.SAVE_SECTION_SUCCESS:
			return {
				...state,
				section: payload.section
			}
		case actions.SAVE_SECTION_FAILED:
			return {
				...state,
				error: payload.error
			}
		default:
			return state;
	}
}
