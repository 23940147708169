import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Layout, ConfigProvider } from 'antd';
import { IntlProvider } from 'react-intl';
import { Debounce } from 'react-throttle';
import WindowResizeListener from 'react-window-size-listener';
import { ThemeProvider } from 'styled-components';
import authAction from '@iso/redux/auth/actions';
import appActions from '@iso/redux/app/actions';
import AxaltaSidebar from '../Sidebar/Sidebar';
import AxaltaTopbar from '../Topbar/Topbar';
import AppRouter from './AppRouter';
import { AppLocale } from '../../dashApp';
import themes from '@iso/settings/themes';
import AppHolder from './commonStyle';
import './global.css';
import background_h from '../../image/background_dashboard.jpg';
const { Content, Footer } = Layout;
const { logout } = authAction;
const { toggleAll } = appActions;
export class App extends Component {

	render() {
		const { url } = this.props.match;
		const { history } = this.props;
		const { locale, selectedTheme, height } = this.props;
		const currentAppLocale = AppLocale[locale];
		const appHeight = window.innerHeight;
		return (
			<ConfigProvider locale={currentAppLocale.antd}>
				<IntlProvider
					locale={currentAppLocale.locale}
					messages={currentAppLocale.messages} >
					<ThemeProvider theme={themes[selectedTheme]}>
						<AppHolder>
							<Layout
								style={{
									height: appHeight
								}} >
								<Debounce time="1000" handler="onResize">
									<WindowResizeListener
										onResize={windowSize =>
											this.props.toggleAll(
												windowSize.windowWidth,
												windowSize.windowHeight
											)
										}
									/>
								</Debounce>
								<AxaltaTopbar url={url} history={history} />
								<Layout
									style={{
										flexDirection: 'row',
										overflowX: 'hidden'
									}} >
									<AxaltaSidebar url={url} />
									<Layout
										className="isoContentMainLayout"
										style={{
											height: height
										}} >
										<Content
											className="isomorphicContent"
											style={{
												padding: '50px 0px 20px 20px',
												flexShrink: '0',
												background: `url(${background_h})`,
												position: 'relative',
											}} >
											<AppRouter url={url} />
										</Content>
										<Footer
											style={{
												height: '70px',
												width: ' 100%',
												position: 'bottom',
												textAlign: 'center'
											}} >
											AXALTA COATING SYSTEMS MEXICO @ 2021
										</Footer>
									</Layout>
								</Layout>
							</Layout>
						</AppHolder>
					</ThemeProvider>
				</IntlProvider>
			</ConfigProvider>
		);
	}
}

export default connect(
	state => ({
		auth: state.Auth,
		locale: state.LanguageSwitcher.language.locale,
		selectedTheme: state.ThemeSwitcher.changeThemes.themeName,
		height: state.App.height
	}),
	{ logout, toggleAll }
)(App);
