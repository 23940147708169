const SECTION = 'SECTION_';

const actionsSec = {
	LOAD_SECTION: SECTION + 'LOAD_SECTION',
	LOAD_SECTION_SUCCESS: SECTION + 'LOAD_SECTION_SUCCESS',
	LOAD_SECTION_FAILED: SECTION + 'LOAD_SECTION_FAILED',

	DELETE_SECTION: SECTION + 'DELETE_SECTION',
	DELETE_SECTION_SUCCESS: SECTION + 'DELETE_SECTION_SUCCESS',
	DELETE_SECTION_FAILED: SECTION + 'DELETE_SECTION_FAILED',

	UPDATE_SECTION: SECTION + 'UPDATE_SECTION',
	UPDATE_SECTION_SUCCESS: SECTION + 'UPDATE_SECTION_SUCCESS',
	UPDATE_SECTION_CANCEL: SECTION + 'UPDATE_SECTION_CANCEL',

	SAVE_SECTION: SECTION + 'SAVE_SECTION',
	SAVE_SECTION_SUCCESS: SECTION + 'SAVE_SECTION_SUCCESS',
	SAVE_SECTION_FAILED: SECTION + 'SAVE_SECTION_FAILED',

	loadSection: () => ({
		type: actionsSec.LOAD_SECTION
	}),
	loadSectionSuccess: section => ({
		type: actionsSec.LOAD_SECTION_SUCCESS,
		payload: { section: section }
	}),
	loadSectionFailed: error => ({
		type: actionsSec.LOAD_SECTION_FAILED,
		payload: { error }
	}),
	deleteSection: idSectopm => ({
		type: actionsSec.DELETE_SECTION,
		payload: idSectopm
	}),
	deleteSectionSuccess: () => ({
		type: actionsSec.DELETE_SECTION_SUCCESS
	}),
	deleteSectionFailed: error => ({
		type: actionsSec.DELETE_SECTION_FAILED,
		payload: { error }
	}),
	saveSection: section => ({
		type: actionsSec.SAVE_SECTION,
		payload: section
	}),
	saveSectionSuccess: () => ({
		type: actionsSec.SAVE_SECTION_SUCCESS
	}),
	saveSectionFailed: error => ({
		type: actionsSec.SAVE_SECTION_FAILED,
		payload: { error }
	}),
	
	updateSection: section => ({
		type: actionsSec.UPDATE_SECTION,
		payload: section
	}),
	updateSectionSuccess: () => ({
		type: actionsSec.UPDATE_SECTION_SUCCESS
	}),
	updateSectionCancel: () => ({
		type: actionsSec.UPDATE_SECTION_CANCEL
	})
};

export default actionsSec;
