import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import FirebaseHelper from '../../helpers/firebase/index';
import { notification } from '@iso/components/index';
//import '@firebase/firestore';

const { database, firestore } = FirebaseHelper;

const nameDecodedRegex = /.*\/o(.*\/)(.*)\.(.*)\?.*/g;
const getDocumentInfo = downloadUrl => {
	const decodedDownload = decodeURIComponent(downloadUrl);
	let fileLocation = '';
	let fileName = '';
	let fileExtension = '';
	try {
		fileLocation = decodedDownload.replace(nameDecodedRegex, '$1');

		fileExtension = decodedDownload
			.replace(nameDecodedRegex, '$3')
			.trim()
			.toLowerCase();
		fileName =
			decodedDownload.replace(nameDecodedRegex, '$2') +
			'.' +
			fileExtension;
		return { fileName, fileExtension, fileLocation };
	} catch (error) {
		console.log(error);
		throw error;
	}
};

const getBanners = async () =>
	await database
		.collection('banners')
		.get()
		.then(queryResponse => {
			const result = [];
			if (queryResponse.size > 0) {
				queryResponse.forEach(doc => {
					const docInfo = getDocumentInfo(doc.data().imageUrl);
					const banner = {
						...doc.data(),
						uid: doc.id,
						...docInfo
					};
					result.push(banner);
				});
			}
			return result;
		})
		.catch(error => {
			throw error;
		});

function* loadBanners() {
	try {
		const banners = yield getBanners();
		yield put(actions.loadBannersSuccess(banners));
	} catch (error) {
		console.log(error);
		yield put(actions.loadBannersFailed({ error }));
	}
}
const deleteBanner = async bannerId =>
	await database
		.collection('banners')
		.doc(bannerId)
		.delete()
		.then(value => {
			console.log(value);
			return value;
		})
		.catch(error => {
			throw error;
		});
function* removeBanner({ payload: { bannerId } }) {
	try {
		const deletedBanner = yield deleteBanner(bannerId);
		yield put(actions.removeBannerSuccess(deletedBanner));
		yield put(actions.loadBanners());
	} catch (error) {
		console.log(error);
		yield put(actions.removeBannerFailed(error));
	}
}

const insertBannerAsc = async (imageUrl, category) =>
	await database
		.collection('banners')
		.doc()
		.set({
			created: firestore.FieldValue.serverTimestamp(),
			imageUrl,
			category
		})
		.then(value => {
			return { value };
		})
		.catch(error => {
			console.log(error);
			throw error;
		});

function* saveDocument({ payload: { imageUrl = '', id, category = '' } }) {
	try {
		if (imageUrl.trim().length === 0) {
			throw new Error({
				errorCode: 88123479,
				description: 'Para guardar un banner, necesita tener una imagen'
			});
		}
		var bannerDoc = {
			imageUrl,
			category
		};

		if (id) {
			bannerDoc.id = id;
		}
		yield call(async () => await insertBannerAsc(imageUrl, category));
		yield put(actions.saveDocumentSuccess());
		notification('success', 'El documento se guardó correctamente');
		yield put(actions.loadBanners());
	} catch (error) {
		const { errorCode, description } = error;
		if (errorCode && errorCode === 88123479) {
			notification('error', description);
			return;
		}
		yield put(actions.saveDocumentFailed(error));
		notification(
			'error',
			'No se pudo guardar el documento, intente mas tarde'
		);
	}
}

export default function* rootSaga() {
	yield all([
		takeEvery(actions.LOAD_BANNERS, loadBanners),
		takeEvery(actions.REMOVE_BANNER, removeBanner),
		takeEvery(actions.SAVE_DOCUMENT, saveDocument)
	]);
}
