const DOCUMENT = 'USER_';

const actions = {
    GET_USER: 'GET_USER',
    GET_USER_SUCCESS: 'GET_USER_SUCCESS',
    UPDATE_USER: 'UPDATE_USER',
    UPDATE_USER_SAGA: 'UPDATE_USER_SAGA',
    SELECT_CURRENT_USER: 'SELECT_CURRENT_USER',
    CURRENT_USER: 'CURRENT_USER',
    CURRENT_USER_SUCCESS: 'CURRENT_USER_SUCCESS',
    CURRENT_USER_ERROR: 'CURRENT_USER_ERROR',
    TOGGLE_VIEW: 'USER_TOGGLE_VIEW',
    UPDATE_EDIT_USER: 'USER_UPDATE_EDIT_USER',
    LOAD_FROM_FIRESTORE: DOCUMENT + 'LOAD_FROM_FIRESTORE',
    LOAD_FROM_FIRESTORE_SUCCESS: DOCUMENT + 'LOAD_FROM_FIRESTORE_SUCCESS',
    LOAD_FROM_FIRESTORE_ERROR: DOCUMENT + 'LOAD_FROM_FIRESTORE_ERROR',
    LOAD_ALL_USERS_FROM_FIRESTORE: DOCUMENT + 'LOAD_ALL_USERS_FROM_FIRESTORE',
    SAVE_INTO_FIRESTORE: DOCUMENT + 'SAVE_INTO_FIRESTORE',
    SAVE_INTO_FIRESTORE_ERROR: DOCUMENT + 'SAVE_INTO_FIRESTORE_ERROR',
    DELETE_INTO_FIRESTORE: DOCUMENT + 'DELETE_INTO_FIRESTORE',
    DELETE_INTO_FIRESTORE_SUCCESS: DOCUMENT + 'DELETE_INTO_FIRESTORE_SUCCESS',
    DELETE_INTO_FIRESTORE_ERROR: DOCUMENT + 'DELETE_INTO_FIRESTORE_ERROR',

    RESET_FIRESTORE_DOCUMENTS: DOCUMENT + 'RESET_FIRESTORE_DOCUMENTS',
    RESET_FIRESTORE_DOCUMENTS_ERROR: DOCUMENT + 'RESET_FIRESTORE_DOCUMENTS_ERROR',

    TOGGLE_FIRESTORE_HANDLE_MODAL: DOCUMENT + 'TOGGLE_FIRESTORE_HANDLE_MODAL',
    FIRESTORE_UPDATE: DOCUMENT + 'FIRESTORE_UPDATE',

    UPDATE_USER_DB: 'UPDATE_USER_DB',
    UPDATE_USER_DB_SUCCESS: 'UPDATE_USER_DB_SUCCESS',
    UPDATE_USER_DB_ERROR: 'UPDATE_USER_DB_ERROR',

    getUser: uid => {
        return {
            type: actions.GET_USER,
            payload: {
                uid
            }
        }
    },
    getUserSuccess: userDB => {

        return {
            type: actions.GET_USER_SUCCESS,
            payload: {
                userDB
            }
        }
    },

    currentUser: () => {
        return { type: actions.CURRENT_USER };
    },
    currentUserSuccess: currentUser => {
        return {
            type: actions.CURRENT_USER_SUCCESS,
            payload: {
                currentUser
            }
        }
    },
    currentUserError: error => {
        return {
            type: actions.CURRENT_USER_ERROR,
            payload: {
                error
            }
        }
    },
    loadFromFireStore: () => {
        return { type: actions.LOAD_FROM_FIRESTORE };
    },
    loadAllUsersFromFireStore: () => {
        return { type: actions.LOAD_ALL_USERS_FROM_FIRESTORE };
    },

    loadFromFireStoreSuccess: data => ({
        type: actions.LOAD_FROM_FIRESTORE_SUCCESS,
        payload: {
            data
        }
    }),

    loadFromFireStoreError: error => ({
        type: actions.LOAD_FROM_FIRESTORE_ERROR,
        payload: {
            error
        }
    }),

    saveIntoFireStore: (data, actionName = 'insert') => ({
        type: actions.SAVE_INTO_FIRESTORE,
        payload: {
            data,
            actionName
        }
    }),
    deleteIntoFireStore: (data, actionName = 'insert') => ({
        type: actions.DELETE_INTO_FIRESTORE,
        payload: {
            data,
            actionName
        }
    }),
    deleteIntoFireStoreSuccess: (data, actionName = 'insert') => ({
        type: actions.DELETE_INTO_FIRESTORE,
        payload: {
            data,
            actionName
        }
    }),
    deleteIntoFireStoreError: (data, actionName = 'insert') => ({
        type: actions.DELETE_INTO_FIRESTORE,
        payload: {
            data,
            actionName
        }
    }),

    toggleModal: (data = null) => ({
        type: actions.TOGGLE_FIRESTORE_HANDLE_MODAL,
        payload: {
            data
        }
    }),

    update: data => ({
        type: actions.FIRESTORE_UPDATE,
        payload: {
            data
        }
    }),

    saveIntoFireStoreError: error => ({
        type: actions.SAVE_INTO_FIRESTORE_ERROR,
        payload: {
            error
        }
    }),

    resetFireStoreDocuments: () => ({ type: actions.RESET_FIRESTORE_DOCUMENTS }),

    resetFireStoreDocumentsError: error => ({
        type: actions.RESET_FIRESTORE_DOCUMENTS_ERROR,
        payload: {
            error
        }
    }),

    initData: () => ({ type: actions.GET_USER }),
    deleteUser: selected => {
        return (dispatch, getState) => {

            const users = getState().Users.users;
            const newUsers = [];
            users.forEach(user => {
                const selectedIndex = selected.indexOf(user.key);
                if (selectedIndex === -1) {
                    newUsers.push(user);
                }
            });
            dispatch({ type: actions.UPDATE_USER_SAGA, users: newUsers });
        };
    },
    updateUser: user => {
        return (dispatch, getState) => {
            const users = getState().Users.users;
            const index = users
                .map(usr => usr.id)
                .indexOf(user.id);
            if (index === -1) {
                users.push(user);
            } else {
                users[index] = user;
            }
            dispatch({ type: actions.UPDATE_USER_SAGA, users, user });
        };
    },

    selectCurrentUser: id => ({ type: actions.SELECT_CURRENT_USER, id }),
    toggleView: view => ({ type: actions.TOGGLE_VIEW, view }),
    editUser: user => ({ type: actions.UPDATE_EDIT_USER, user }),

    updateUserDataBase: (userDB, uid) => ({ type: actions.UPDATE_USER_DB, payload: {userDB: userDB, uid: uid} }),
    updateUserDataBaseSuccess: userDB => ({ type: actions.UPDATE_USER_DB_SUCCESS, userDB }),
    updateUserDataBaseError: error => ({ type: actions.UPDATE_USER_DB_ERROR, payload: error })
};
export default actions;