import React, { Component } from "react";
import { connect } from "react-redux";
import clone from "clone";
import { Link } from "react-router-dom";
import { Layout } from "antd";
import { options, optNoAdmin }  from "./options";
import Scrollbars from "@iso/components/utility/customScrollBar.js";
import Menu from "@iso/components/uielements/menu";
import IntlMessages from "@iso/components/utility/intlMessages";
import SidebarWrapper from "./sidebar.style";
import appActions from "@iso/redux/app/actions";
import Logo from "@iso/components/utility/logo";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;
const { Sider } = Layout;

const {
  toggleOpenDrawer,
  changeOpenKeys,
  changeCurrent,
  toggleCollapsed
} = appActions;
const stripTrailingSlash = str => {
  if (str.substr(-1) === "/") {
    return str.substr(0, str.length - 1);
  }
  return str;
};

class AxaltaSidebar extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.onOpenChange = this.onOpenChange.bind(this);
  }

  
  handleClick(e) {
    this.props.changeCurrent([e.key]);
    if (this.props.app.view === "MobileView") {
      setTimeout(() => {
        this.props.toggleCollapsed();
        this.props.toggleOpenDrawer();
      }, 100);
    }
  }
  onOpenChange(newOpenKeys) {
    const { app, changeOpenKeys } = this.props;
    const latestOpenKey = newOpenKeys.find(
      key => !(app.openKeys.indexOf(key) > -1)
    );
    const latestCloseKey = app.openKeys.find(
      key => !(newOpenKeys.indexOf(key) > -1)
    );
    let nextOpenKeys = [];
    if (latestOpenKey) {
      nextOpenKeys = this.getAncestorKeys(latestOpenKey).concat(latestOpenKey);
    }
    if (latestCloseKey) {
      nextOpenKeys = this.getAncestorKeys(latestCloseKey);
    }
    changeOpenKeys(nextOpenKeys);
  }
  getAncestorKeys = key => {
    const map = {
      sub3: ["sub2"]
    };
    return map[key] || [];
  };
  admin(){
    
    const { app, customizedTheme } = this.props;
    const collapsed = clone(app.collapsed) && !clone(app.openDrawer);
    // const { openDrawer } = app;
    const mode = collapsed === true ? "vertical" : "inline";
    // const onMouseEnter = event => {
    //   if (openDrawer === false) {
    //     toggleOpenDrawer();
    //   }
    //   return;
    // };
    // const onMouseLeave = () => {
    //   if (openDrawer === true) {
    //     toggleOpenDrawer();
    //   }
    //   return;
    // };
    // const styling = {
    //   backgroundColor: customizedTheme.backgroundColor
    // };
    const submenuStyle = {
     
      color: customizedTheme.textColor
    };
    const submenuColor = {
      color: customizedTheme.textColor
    };
    // if ((this.props.admin === undefined || this.props.admin !==true)){
    const isAdmin = localStorage.getItem('userRole') === 'administrador' ? false : true;

    if (isAdmin){
    return (  <Menu
      onClick={this.handleClick}
      theme="light"
      className="isoDashboardMenu"
      mode={mode}
      openKeys={collapsed ? [] : app.openKeys}
      selectedKeys={app.current}
      onOpenChange={this.onOpenChange}
    >
      <div className="isoMenuLogo">
        <h5>Dashboard</h5>
      </div>
     {optNoAdmin.map(singleOption =>
        this.getMenuItem({ submenuStyle, submenuColor, singleOption })
      )}  
      <SubMenu
        key="subAxalta"
        title={
          <span className="isoMenuHolder" style={submenuColor}>
            <span className="nav-text">
              <i className={"ion-folder"} />
              <IntlMessages id="sidebar.segments" />
            </span>
          </span>
        }
      >
        <MenuItemGroup
          key="axaltaItem4"
          title={<IntlMessages id="sidebar.repAuto" />}
        >
         <Menu.Item style={submenuStyle} key="13">
            <Link to='/dashboard/repAuto/colorTools'>
            <IntlMessages id="sidebar.colorTools" />
            </Link>
          </Menu.Item>
          <Menu.Item style={submenuStyle} key="14">
          <Link to='/dashboard/repAuto/downloads'>
            <IntlMessages id="sidebar.downloads" />
            </Link>
          </Menu.Item>
          <Menu.Item style={submenuStyle} key="15">
          <Link to='/dashboard/repAuto/securityPage'>
            <IntlMessages id="sidebar.securityPage" />
            </Link>
          </Menu.Item> 
          <Menu.Item style={submenuStyle} key="16">
            <Link to='/dashboard/repAuto/technicalPage'>
              <IntlMessages id="sidebar.technicalPage" />
              </Link>
            </Menu.Item> 
        </MenuItemGroup>
        <MenuItemGroup
          key="axaltaItem1"
          title={<IntlMessages id="sidebar.arquitectonic" />}
        >
          <Menu.Item style={submenuStyle} key="1">
            <Link to='/dashboard/architectonic/colorTools'>
            <IntlMessages id="sidebar.colorTools" />
            </Link>
          </Menu.Item>
          <Menu.Item style={submenuStyle} key="2">
          <Link to='/dashboard/architectonic/downloads'>
            <IntlMessages id="sidebar.downloads" />
            </Link>
          </Menu.Item>
           <Menu.Item style={submenuStyle} key="3">
          <Link to='/dashboard/architectonic/securityPage'>
            <IntlMessages id="sidebar.securityPage" />
            </Link>
          </Menu.Item> 
          <Menu.Item style={submenuStyle} key="4">
            <Link to='/dashboard/architectonic/technicalPage'>
              <IntlMessages id="sidebar.technicalPage" />
              </Link>
            </Menu.Item> 
        </MenuItemGroup>
        {/* <MenuItemGroup
          key="axaltaItem3"
          title={<IntlMessages id="sidebar.industry" />}
        >
          <Menu.Item style={submenuStyle} key="9">
            <Link to='/dashboard/industry/colorTools'>
            <IntlMessages id="sidebar.colorTools" />
            </Link>
          </Menu.Item> 
          <Menu.Item style={submenuStyle} key="10">
          <Link to='/dashboard/industry/downloads'>
            <IntlMessages id="sidebar.downloads" />
            </Link>
          </Menu.Item>
         <Menu.Item style={submenuStyle} key="11">
          <Link to='/dashboard/industry/securityPage'>
            <IntlMessages id="sidebar.securityPage" />
            </Link>
          </Menu.Item> 
          <Menu.Item style={submenuStyle} key="12">
            <Link to='/dashboard/industry/technicalPage'>
              <IntlMessages id="sidebar.technicalPage" />
              </Link>
            </Menu.Item> 
        </MenuItemGroup> */}
        <MenuItemGroup
          key="axaltaItem2"
          title={<IntlMessages id="sidebar.floats" />}
        >
        <Menu.Item style={submenuStyle} key="5">
            <Link to='/dashboard/fleets/colorTools'>
            <IntlMessages id="sidebar.colorTools" />
            </Link>
          </Menu.Item> 
          <Menu.Item style={submenuStyle} key="6">
          <Link to='/dashboard/fleets/downloads'>
            <IntlMessages id="sidebar.downloads" />
            </Link>
          </Menu.Item>
         <Menu.Item style={submenuStyle} key="7">
          <Link to='/dashboard/fleets/securityPage'>
            <IntlMessages id="sidebar.securityPage" />
            </Link>
          </Menu.Item> 
          <Menu.Item style={submenuStyle} key="8">
            <Link to='/dashboard/fleets/technicalPage'>
              <IntlMessages id="sidebar.technicalPage" />
              </Link>
            </Menu.Item> 
        </MenuItemGroup>
      </SubMenu>
      <Menu.Item key="contacts">
        <Link to={`${stripTrailingSlash(this.props.url)}/contacts`}>
          <span className="isoMenuHolder" style={submenuColor}>
          
            <span className="nav-text">
              <i className={"ion-chatbubbles"} />
              <IntlMessages id="sidebar.contacts" />
            </span>
          </span>
        </Link>
      </Menu.Item>
    </Menu>)
    }else {
      return (  <Menu
        onClick={this.handleClick}
        theme="light"
        className="isoDashboardMenu"
        mode={mode}
        openKeys={collapsed ? [] : app.openKeys}
        selectedKeys={app.current}
        onOpenChange={this.onOpenChange}
      >
        <div className="isoMenuLogo">
          <h5>Dashboard</h5>
        </div>
       {options.map(singleOption =>
          this.getMenuItem({ submenuStyle, submenuColor, singleOption })
        )}  
        <SubMenu
          key="subAxalta"
          title={
            <span className="isoMenuHolder" style={submenuColor}>
              <span className="nav-text">
                <i className={"ion-folder"} />
                <IntlMessages id="sidebar.segments" />
              </span>
            </span>
          }
        >
          <MenuItemGroup
            key="axaltaItem4"
            title={<IntlMessages id="sidebar.repAuto" />}
          >
           <Menu.Item style={submenuStyle} key="13">
              <Link to='/dashboard/repAuto/colorTools'>
              <IntlMessages id="sidebar.colorTools" />
              </Link>
            </Menu.Item>
            <Menu.Item style={submenuStyle} key="14">
            <Link to='/dashboard/repAuto/downloads'>
              <IntlMessages id="sidebar.downloads" />
              </Link>
            </Menu.Item>
            <Menu.Item style={submenuStyle} key="15">
            <Link to='/dashboard/repAuto/securityPage'>
              <IntlMessages id="sidebar.securityPage" />
              </Link>
            </Menu.Item> 
            <Menu.Item style={submenuStyle} key="16">
            <Link to='/dashboard/repAuto/technicalPage'>
              <IntlMessages id="sidebar.technicalPage" />
              </Link>
            </Menu.Item>
          </MenuItemGroup>
          <MenuItemGroup
            key="axaltaItem1"
            title={<IntlMessages id="sidebar.arquitectonic" />}
          >
            <Menu.Item style={submenuStyle} key="1">
              <Link to='/dashboard/architectonic/colorTools'>
              <IntlMessages id="sidebar.colorTools" />
              </Link>
            </Menu.Item>
            <Menu.Item style={submenuStyle} key="2">
            <Link to='/dashboard/architectonic/downloads'>
              <IntlMessages id="sidebar.downloads" />
              </Link>
            </Menu.Item>
             <Menu.Item style={submenuStyle} key="3">
            <Link to='/dashboard/architectonic/securityPage'>
              <IntlMessages id="sidebar.securityPage" />
              </Link>
            </Menu.Item> 
             <Menu.Item style={submenuStyle} key="4">
            <Link to='/dashboard/architectonic/technicalPage'>
              <IntlMessages id="sidebar.technicalPage" />
              </Link>
            </Menu.Item> 
          </MenuItemGroup>
          {/* <MenuItemGroup
            key="axaltaItem3"
            title={<IntlMessages id="sidebar.industry" />}
          >
            <Menu.Item style={submenuStyle} key="9">
              <Link to='/dashboard/industry/colorTools'>
              <IntlMessages id="sidebar.colorTools" />
              </Link>
            </Menu.Item> 
            <Menu.Item style={submenuStyle} key="10">
            <Link to='/dashboard/industry/downloads'>
              <IntlMessages id="sidebar.downloads" />
              </Link>
            </Menu.Item>
           <Menu.Item style={submenuStyle} key="11">
            <Link to='/dashboard/industry/securityPage'>
              <IntlMessages id="sidebar.securityPage" />
              </Link>
            </Menu.Item> 
            <Menu.Item style={submenuStyle} key="12">
            <Link to='/dashboard/industry/technicalPage'>
              <IntlMessages id="sidebar.technicalPage" />
              </Link>
            </Menu.Item>
          </MenuItemGroup> */}
          <MenuItemGroup
            key="axaltaItem2"
            title={<IntlMessages id="sidebar.floats" />}
          >
          <Menu.Item style={submenuStyle} key="5">
              <Link to='/dashboard/fleets/colorTools'>
              <IntlMessages id="sidebar.colorTools" />
              </Link>
            </Menu.Item> 
            <Menu.Item style={submenuStyle} key="6">
            <Link to='/dashboard/fleets/downloads'>
              <IntlMessages id="sidebar.downloads" />
              </Link>
            </Menu.Item>
           <Menu.Item style={submenuStyle} key="7">
            <Link to='/dashboard/fleets/securityPage'>
              <IntlMessages id="sidebar.securityPage" />
            </Link>
          </Menu.Item>
          <Menu.Item style={submenuStyle} key="8">
            <Link to='/dashboard/fleets/technicalPage'>
              <IntlMessages id="sidebar.technicalPage" />
              </Link>
            </Menu.Item>
          </MenuItemGroup>
        </SubMenu>
        <Menu.Item key="contacts">
          <Link to={`${stripTrailingSlash(this.props.url)}/contacts`}>
            <span className="isoMenuHolder" style={submenuColor}>
            
              <span className="nav-text">
                <i className={"ion-chatbubbles"} />
                <IntlMessages id="sidebar.contacts" />
              </span>
            </span>
          </Link>
        </Menu.Item>
      </Menu>)
    }

   
  }
  getMenuItem = ({ singleOption, submenuStyle, submenuColor }) => {
    const { key, label, children, leftIcon } = singleOption;

    const url = stripTrailingSlash(this.props.url);
    if (children) {
      return (
        <SubMenu
          key={key}
          title={
            <span className="isoMenuHolder" style={submenuColor}>
              <span className="nav-text">
                <i className={leftIcon} />
                <IntlMessages id={label} />
              </span>
            </span>
          }
        >
          {children.map(child => {
            const linkTo = child.withoutDashboard
              ? `/${child.key}`
              : `${url}/${child.key}`;
            return (
              <Menu.Item style={submenuStyle} key={child.key}>
                <Link style={submenuColor} to={linkTo}>
                  <IntlMessages id={child.label} />
                </Link>
              </Menu.Item>
            );
          })}
        </SubMenu>
      );
    }
    return (
      <Menu.Item key={key}>
        <Link to={`${url}/${key}`}>
          <span className="isoMenuHolder" style={submenuColor}>
          
            <span className="nav-text">
              <i className={leftIcon} />
              <IntlMessages id={label} />
            </span>
          </span>
        </Link>
      </Menu.Item>
    );
  };
  render() {
    const { app, toggleOpenDrawer, customizedTheme, height } = this.props;
    const collapsed = clone(app.collapsed) && !clone(app.openDrawer);
    const { openDrawer } = app;
    // const mode = collapsed === true ? "vertical" : "inline";
    const onMouseEnter = event => {
      if (openDrawer === false) {
        toggleOpenDrawer();
      }
      return;
    };
    const onMouseLeave = () => {
      if (openDrawer === true) {
        toggleOpenDrawer();
      }
      return;
    };
    const styling = {
      backgroundColor: customizedTheme.backgroundColor
    };
    // const submenuStyle = {
    //   color: customizedTheme.textColor
    // };
    // const submenuColor = {
    //   color: customizedTheme.textColor
    // };
    return (
      <SidebarWrapper>
        <Sider
          trigger={null}
          collapsible={true}
          collapsed={collapsed}
          width={280}
          className="isomorphicSidebar"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          style={styling}
        >
          <Logo collapsed={collapsed} />
          <Scrollbars style={{ height: height - 100 }}>
            {this.admin()}
          </Scrollbars>
        </Sider>
      </SidebarWrapper>
    );
  }
}
const mapStateToProps = state =>(
  { ...state.Auth,
  app: state.App,
  customizedTheme: state.ThemeSwitcher.sidebarTheme,
  height: state.App.height
})

export default connect(
  mapStateToProps,
  { toggleOpenDrawer, changeOpenKeys, changeCurrent, toggleCollapsed }
)(AxaltaSidebar);
