import { all, takeEvery, put, call } from "redux-saga/effects";
import actions from "./actions";
import omit from "lodash/omit";
import FirebaseHelper from "../../helpers/firebase/index";
import "@firebase/firestore";

const {
  // database,
  rsfFirestore,
  getLocations,
  getAddressFromLatLng,
  getStoreList
} = FirebaseHelper;

const COLLECTION_NAME = "locations"; // change your collection

const loadFromFirestoreAs = async () =>
  await getStoreList()
    .then(data => {
      const geoLocationsResult = [];
      data.data.forEach(geoLocations => {
        geoLocationsResult.push(geoLocations);
      });
      return geoLocationsResult;
    })
    .catch(error => {
      console.log(error);
      actions.loadFromFireStoreError(error);
    });

function* loadFromFirestore() {
  try {
    const geoLocationsRes = yield call(loadFromFirestoreAs);
    yield put(actions.loadFromFireStoreSuccess(geoLocationsRes));
  } catch (error) {
    console.log(error);
  }
}

function* storeIntoFirestore({ payload }) {
  const { data, actionName } = payload;
  try {
    switch (actionName) {
      case "delete":
        yield call(rsfFirestore.setDocument, `${COLLECTION_NAME}/${data.key}`, {
          deleted_at: new Date().getTime()
        });
        break;
      case "update":
        yield call(rsfFirestore.setDocument, `${COLLECTION_NAME}/${data.key}`, {
          ...omit(data, ["key"])
        });
        break;
      default:
        yield call(rsfFirestore.addDocument, COLLECTION_NAME, data);
        break;
    }
    yield put({ type: actions.LOAD_FROM_FIRESTORE });
  } catch (error) {
    console.log(error);
    yield put(actions.saveIntoFireStoreError(error));
  }
}

const getLocationsGoogleAsc = async values =>
  await getLocations(values)
    .then(data => {
      return data;
    })
    .catch(error => {
      throw error;
    });

function* loadFromGoogleMaps(values) {
  try {
    const { payload } = values;
    const locationsRes = yield getLocationsGoogleAsc(payload);
    const points = [];
    locationsRes.forEach(location => {
      points.push({
        lat: location.lat,
        lng: location.lng
      });
    });
    let newData = {
      locations: locationsRes,
      points
    };

    if (locationsRes.length === 1) {
      newData = {
        ...newData,
        selectedAddress: locationsRes[0]
      };
    }

    yield put(actions.loadFromGoogleMapsSuccess(newData));
  } catch (error) {
    console.log(error);
    yield put({ type: actions.LOAD_FROM_FIRESTORE, error: error });
  }
}

const getLocationFromLatLngAsc = async ({ lat, lng }) =>
  await getAddressFromLatLng(lat, lng)
    .then(data => {
      return data;
    })
    .catch(error => {
      throw error;
    });

function* getLocationFromLatLng({ payload }) {
  try {
    const { latlng } = payload;
    const locationsRes = yield getLocationFromLatLngAsc(latlng);
    const points = [];

    if (locationsRes.length >= 1) {
      points.push({
        lat: locationsRes[0].lat,
        lng: locationsRes[0].lng
      });
      yield put(
        actions.loadFromGoogleMapsSuccess({
          locations: [locationsRes[0]],
          points,
          selectedAddress: locationsRes[0]
        })
      );
    }
  } catch (error) {
    console.log(error);
  }
}

// const readAllFirestoreDocuments = async () =>
//   await database
//     .collection(COLLECTION_NAME)
//     .get()
//     .then(querySnapshot => {
//       const documents = [];
//       try {
//         querySnapshot.forEach(doc => {
//           documents.push(doc.id);
//         });
//       } catch (e) {}
//       return documents;
//     });

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_FROM_FIRESTORE, loadFromFirestore),
    takeEvery(actions.SAVE_INTO_FIRESTORE, storeIntoFirestore),
    takeEvery(actions.LOAD_FROM_GOOGLE_MAPS, loadFromGoogleMaps),
    takeEvery(actions.GET_LOCATION_FROM_LATLNG, getLocationFromLatLng)
  ]);
}
