import actions from "./actions";

const initState = {
  isLoading: false,
  errorMessage: false,
  geoLocation: [],
  geoLocationId: 0,
  foundLocations: [],
  selectedDirectionKeys: [],
  selectedLocation: {},
  points: [],
  searchId: 0,
  modalActive: false,
  storeRecord: {
    address: {
      apt: "",
      city: "",
      country: "",
      county: "",
      neighborhood: "",
      state: "",
      street: "",
      streetNumber: "",
      zipCode: ""
    },
    location: {
      lat: "",
      lng: ""
    },
    storeData: {
      contactNumber: "",
      respName: "",
      sapCode: "",
      storeName: ""
    }
  }
};

export default function reducer(
  state = initState,
  { type, payload, newRecord }
) {
  switch (type) {
    case actions.LOAD_FROM_FIRESTORE:
      return {
        ...state,
        isLoading: true,
        errorMessage: false,
        modalActive: false
      };
    case actions.LOAD_FROM_FIRESTORE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        geoLocation: payload.data,
        geoLocationId: state.geoLocationId + 1,
        errorMessage: false
      };
    case actions.LOAD_FROM_FIRESTORE_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: "There is a loading problem"
      };
    case actions.LOAD_FROM_GOOGLE_MAPS:
      return {
        ...state,
        isLoading: true,
        errorMessage: false,
        modalActive: false
      };
    case actions.LOAD_FROM_GOOGLE_MAPS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        foundLocations: payload.data.locations,
        points: payload.data.points,
        errorMessage: false,
        selectedDirectionKeys: payload.data.selectedAddress
          ? [payload.data.selectedAddress.ID]
          : [],
        selectedLocation: payload.data.selectedAddress
          ? payload.data.selectedAddress
          : {},
        searchId: state.searchId + 1
      };
    case actions.LOAD_FROM_GOOGLE_MAPS_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: "There is a loading problem"
      };
    case actions.SELECT_FROM_GOOGLE_MAPS:
      return {
        ...state,
        selectedLocation: payload.data,
        selectedDirectionKeys: [payload.data.ID]
      };
    case actions.TOGGLE_FIRESTORE_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        geoLocationNew:
          payload.data == null ? initState.geoLocationNew : payload.data
      };
    case actions.FIRESTORE_UPDATE:
      return {
        ...state,
        geoLocationNew: payload.data
      };
    case actions.CHANGE_FIELD_VALUES:
      console.log(payload);

      /*                storeRecord: {
                    address: {
                        apt: "",
                        city: "",
                        country: "",
                        county: "",
                        neighborhood: "",
                        state: "",
                        street:"",
                        streetNumber: "",
                        zipCode: ""
                    },
                    location: {
                        lat: "",
                        lng: ""
                    },
                    storeData: {
                        contactNumber: "",
                        respName: "",
                        sapCode: "",
                        storeName: ""
                    }
                } */

      return {
        ...state
      };
    default:
      return state;
  }
}
