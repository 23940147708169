import actions from './actions';

const initState = {
    isLoading: false,
    errorMessage: false,
    deals: {},
    modalActive: false,
};

export default function reducer(
    state = initState, { type, payload }
) {
    switch (type) {
        case actions.LOAD_FROM_FIRESTORE:
            return {
                ...state,
                isLoading: true,
                errorMessage: false,
                modalActive: false,
            };
        case actions.LOAD_FROM_FIRESTORE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                deals: payload.data,
                errorMessage: false,
            };
        case actions.LOAD_FROM_FIRESTORE_ERROR:
            return {
                ...state,
                isLoading: false,
                errorMessage: 'There is a loading problem',
            };
        case actions.TOGGLE_FIRESTORE_HANDLE_MODAL:
            return {
                ...state,
                modalActive: !state.modalActive,
                deals: payload.data == null ? initState.deals : payload.data,
            };
        case actions.FIRESTORE_UPDATE:
            return {
                ...state,
                deals: payload.data,
            };
        case actions.LOAD_BY_TYPE_FIRESTORE:
            return {
                ...state,
                isLoading: true,
                errorMessage: false,
                modalActive: false,
            };
        case actions.LOAD_BY_TYPE_FIRESTORE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                deals: payload.data,
                errorMessage: false
            };
        default:
            return state;
    }
}