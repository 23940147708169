import { all, takeEvery, put, call, take } from 'redux-saga/effects';
import actions from './actions';
import FirebaseHelper from '../../helpers/firebase';
import '@firebase/firestore';

const {
    // database,
    rsfFirestore,
    // processFireStoreCollection,
    getUsers,
    getAllUsers,
    getUser,
    getCurrentUser,
    updateUserDB
} = FirebaseHelper;

const COLLECTION_NAME = 'users'; // change your collection

const loadFromFirestoreAs = async() =>
    await getUsers()
    .then(data => {
        const usersResult = [];
        data.data.forEach(user => {
            const { created } = user;
            var options = {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
            };
            const creationDate = new Date(created).toLocaleDateString(
                'es-MX',
                options
            );
            const newUser = {...user, createdText: creationDate };
            usersResult.push(newUser);
        });
        return usersResult;
    })
    .catch(error => {
        actions.loadFromFireStoreError(error);
    });

function* loadFromFireStore() {
    try {
        const usersRes = yield call(loadFromFirestoreAs);
        yield put(actions.loadFromFireStoreSuccess(usersRes));
    } catch (error) {
        console.log(error);
        yield put(actions.loadFromFireStoreError(error));
    }
}

const getUsrAs = async uid => {

    const currentUserRes = await getUser({ userId: uid });
    return currentUserRes;
};

function* getUserCall(payload) {

    const { uid } = payload.payload;
    try {
        const result = yield getUsrAs(uid);
        const {
            email,
            Username,
            surname,
            sapCode,
            company,
            phoneNumber,
            phoneMobile,
            address1,
            address2,
            neighborhood,
            zipCode,
            city,
            state1,
            repoAuto,
            arquitectonic,
            industry,
            float,
            executiveName,
            branchNumber,
            photoURL
        } = result.data;
        yield actions.getUserSuccess(result.data);
        yield put({
            type: actions.GET_USER_SUCCESS,
            payload: {
                email: email,
                Username: Username,
                surname: surname,
                sapCode: sapCode,
                company: company,
                phoneNumber: phoneNumber,
                phoneMobile: phoneMobile,
                address1: address1,
                address2: address2,
                neighborhood: neighborhood,
                zipCode: zipCode,
                city: city,
                state1: state1,
                repoAuto: repoAuto,
                arquitectonic: arquitectonic,
                industry: industry,
                float: float,
                executiveName: executiveName,
                branchNumber: branchNumber,
                photoURL: photoURL
            }
        });
        return result;
    } catch (error) {
        console.log(error);
        return error;
    }
}

function* getCurrUser() {
    try {
        const currentUserRes = yield call(getCurrentUser);
        const result = yield take(currentUserRes);
        const {
            email,
            displayName,
            uid,
            phoneNumber,
            photoURL
        } = result.currentUser;
        yield actions.currentUserSuccess(result);
        yield put({
            type: actions.CURRENT_USER_SUCCESS,
            payload: {
                email: email,
                displayName: displayName,
                uid: uid,
                phoneNumber: phoneNumber,
                photoURL: photoURL
            }
        });
        return result;
    } catch (error) {
        yield actions.currentUserError(error);
    }
}
const loadAllUsersFromFirestoreAs = async() =>
    await getAllUsers()
    .then(data => {
        const usersResult = [];
        const orderResult = data.data.sort((a, b) => {
            if (a.created < b.created) {
              return 1;
            }
            if (a.created > b.created) {
              return -1;
            }
            // a must be equal to b
            return 0;
          });
          orderResult.forEach(user => {
            const { created } = user;
            var options = {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
            };
            const creationDate = new Date(created).toLocaleDateString(
                'es-MX',
                options
            );
            const fullName =
                user.Username + (user.surname ? ' ' + user.surname : '');
            const newUser = {
                ...user,
                createdText: creationDate,
                fullName
            };
            usersResult.push(newUser);
        });
        return usersResult;
    })
    .catch(error => {
        actions.loadFromFireStoreError(error);
    });

function* loadAllUsersFromFireStore() {
    try {
        const usersRes = yield call(loadAllUsersFromFirestoreAs);
        yield put(actions.loadFromFireStoreSuccess(usersRes));
    } catch (error) {
        console.log(error);
        yield put(actions.loadFromFireStoreError(error));
    }
}

function* deleteIntoFireStore({ payload }) {
    const { data, actionName } = payload;
    try {
        switch (actionName) {
            case 'remove':
                yield call(
                    rsfFirestore.deleteDocument,
                    `${COLLECTION_NAME}/${data.uid}`, {
                        ['key']: `${data.uid}`
                    }
                )
                yield put({ type: actions.LOAD_ALL_USERS_FROM_FIRESTORE });
                break;
            default:
                break;
        }
    } catch (error) {
        console.log(error);
        yield put(actions.saveIntoFireStoreError(error));
    }
}

function* storeIntoFireStore({ payload }) {
    const { data, actionName } = payload;
    try {

        switch (actionName) {

            case 'delete':
                yield call(
                    rsfFirestore.deleteDocument,
                    `${COLLECTION_NAME}/${data.uid}`, {
                        ['key']: `${data.uid}`
                    }
                );
                break;
            case 'remove':
                yield call(
                    rsfFirestore.deleteDocument,
                    `${COLLECTION_NAME}/${data.uid}`, {
                        ['key']: `${data.uid}`
                    }
                )
                break;

            case 'update':
            case 'accept':
                yield call(
                    rsfFirestore.updateDocument,
                    `${COLLECTION_NAME}/${data.uid}`, { status: 1 }
                );
                break;
            default:
                yield call(rsfFirestore.addDocument, COLLECTION_NAME, data);
                break;
        }
        yield put({ type: actions.LOAD_FROM_FIRESTORE });
    } catch (error) {
        console.log(error);
        yield put(actions.saveIntoFireStoreError(error));
    }
}

const updateUserDataBaseAs = async(userDB, uid) => {
    const result = await updateUserDB(userDB, uid);
    return result;
}

function* updateUserDataBase(payload) {
    try {
        const { userDB, uid } = payload.payload;
        const result = yield updateUserDataBaseAs(userDB, uid);
        const {
            email,
            Username,
            surname,
            sapCode,
            company,
            phoneNumber,
            phoneMobile,
            address1,
            address2,
            branchNumber,
            neighborhood,
            zipCode,
            city,
            state1,
            repoAuto,
            arquitectonic,
            industry,
            float,
            executiveName,
            photoURL
        } = result.data;
        yield put({
            type: actions.GET_USER_SUCCESS,
            payload: {
                email: email,
                Username: Username,
                surname: surname,
                sapCode: sapCode,
                company: company,
                phoneNumber: phoneNumber,
                phoneMobile: phoneMobile,
                address1: address1,
                address2: address2,
                branchNumber: branchNumber,
                neighborhood: neighborhood,
                zipCode: zipCode,
                city: city,
                state1: state1,
                repoAuto: repoAuto,
                arquitectonic: arquitectonic,
                industry: industry,
                float: float,
                executiveName: executiveName,
                photoURL: photoURL
            }
        });
    } catch (error) {
        console.log('ERROR UpdateUserDataBase', error);
    }
}


// const readAllFirestoreDocuments = async() =>
//     await database
//     .collection(COLLECTION_NAME)
//     .get()
//     .then(querySnapshot => {
//         const documents = [];
//         try {
//             querySnapshot.forEach(doc => {
//                 documents.push(doc.id);
//             });
//         } catch (e) {}
//         return documents;
//     });

export default function* rootSaga() {
    yield all([
        takeEvery(actions.LOAD_FROM_FIRESTORE, loadFromFireStore),
        takeEvery(
            actions.LOAD_ALL_USERS_FROM_FIRESTORE,
            loadAllUsersFromFireStore
        ),
        takeEvery(actions.SAVE_INTO_FIRESTORE, storeIntoFireStore),
        takeEvery(actions.CURRENT_USER, getCurrUser),
        takeEvery(actions.GET_USER, getUserCall),
        takeEvery(actions.UPDATE_USER_DB, updateUserDataBase),
        takeEvery(actions.DELETE_INTO_FIRESTORE, deleteIntoFireStore)
    ]);
}