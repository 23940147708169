import { all, takeEvery, put, call } from 'redux-saga/effects';
import actionsSec from './actions';
import Firebase from '../../helpers/firebase/index';
import { notification } from '@iso/components/index';

const { saveSection, getSection, updateSection, deleteSection } = Firebase;

function* loadSectionAsc() {
	try {
		const section = yield call(
			async () => await getSection()
		);
		const result = [];
		section.forEach(value => {
			result.push({
				key: value.id,
				...value
			})
		})
		yield put(actionsSec.loadSectionSuccess(result));
	} catch (error) {
		console.log(error);
		yield put(actionsSec.loadSectionFailed(error));
	}
}

function* deleteSectionAsc({ payload: { idSection } }) {
	try {
		yield call( async () => await deleteSection(idSection) );
		notification('success', 'Registro eliminado correctamente');
		yield put(actionsSec.loadSection());
	} catch (error) {
		console.log(error);
		yield put(actionsSec.deleteSectionFailed(error));
		notification('error', 'Hubo un error al eliminar el section');
	}
}

function* saveSectionAsc({ payload: { section } }) {
	try {
		yield call(async () => await saveSection(section) );
		notification('success', 'Registro guardado correctamente');
		yield put(actionsSec.loadSection());
	} catch (error) {
		console.log(error);
		yield put(actionsSec.saveSectionFailed(error));
		notification('error', 'Hubo un error al guardar el Registro');
	}
}

function* updateSectionAsc({ payload: { section } }) {
	try {
		yield call(async () => await updateSection(section));
		yield put(actionsSec.updateSectionSuccess());
		notification('success', 'Registro actualizado correctamente');
		yield put(actionsSec.loadSection());
	} catch (error) {
		console.log(error);
		yield put(actionsSec.saveSectionFailed(error));
		notification('error', 'Hubo un error al guardar el Registro');
	}
}

export default function* rootSaga() {
	yield all([
		takeEvery(actionsSec.LOAD_SECTION, loadSectionAsc),
		takeEvery(actionsSec.UPDATE_SECTION, updateSectionAsc),
		takeEvery(actionsSec.DELETE_SECTION, deleteSectionAsc),
		takeEvery(actionsSec.SAVE_SECTION, saveSectionAsc)
	]);
}
