import styled from 'styled-components';
/* 
import { palette } from 'styled-theme'; 
*/
import { transition, borderRadius } from '@iso/settings/style-util';
import WithDirection from '@iso/settings/withDirection';

const SidebarWrapper = styled.div `
  .isomorphicSidebar {
    z-index:50;
    background: #ffffff;
    width: 380px;
    height: 100%;
    flex: 0 0 380px;
    box-shadow: 0 1px 4px -1px rgba(0,0,0,.15);

    .scrollarea {
      height: calc(100vh - 70px);
    }

    @media only screen and (max-width: 767px) {
      width: 240px !important;
      flex: 0 0 240px !important;
    }

    &.ant-layout-sider-collapsed {
      @media only screen and (max-width: 767px) {
        width: 0;
        min-width: 0 !important;
        max-width: 0 !important;
        flex: 0 0 0 !important;
      }
    }

    .isoLogoWrapper {
      height: 70px;
      background: #FAFAFB;
      margin: 0px;
      padding: 0 10px;
      text-align: left;
      overflow: hidden;
      ${borderRadius()};

      h3 {
        a {
          font-size: 21px;
          font-weight: 300;
          line-height: 70px;
          letter-spacing: 3px;
          text-transform: uppercase;
          color: #f1f1f1;
          display: block;
          text-decoration: none;
        }
      }
    }

    &.ant-layout-sider-collapsed {
      .isoLogoWrapper {
        padding: 0;

        h3 {
          a {
            font-size: 27px;
            font-weight: 500;
            letter-spacing: 0;
          }
        }
      }
    }

    .isoDashboardMenu {
      
      background: transparent;
      
      a {
        text-decoration: none;
        font-weight: 400;
      }

      .ant-menu-submenu,
        .ant-menu-submenu-inline {
          .ant-menu-submenu-title {
            &:hover {
              i,
              .nav-text {
                color: #D21026 !important;
              }
            }
          }
        }

      .ant-menu-item {
        width: 100%;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: left;
        align-items: left;
        margin: 0;
        &:hover {
          background-color: #D21026;
          .nav-text {
            color: white !important;
          }
          a {
            &:hover {
              color: white !important;
            }
          }
        }
        &.ant-menu-item-selected {
          > a {
            color: #ffffff;
          }
          span {
            color: #ffffff
          }
        }
      }

      .isoMenuLogo {
        align-items: center;
        padding-top: 30px;
        padding-left: 25px;
        padding-bottom: 20px;
        font-size: 18px;
      }

      .isoMenuHolder {
        display: flex;
        align-items: left;

        i {
          font-size: 17px;
          color: inherit;
          margin: ${props =>
            props['data-rtl'] === 'rtl' ? '0 0 0 30px' : '0 30px 0 0'};
          width: 19px;
          ${transition()};
        }
      }

      .anticon {
        font-size: 18px;
        margin-right: 30px;
        color: inherit;
        ${transition()};
      }

      .nav-text {
        font-size: 14px;
        align-text:left;
        color: inherit;
        font-weight: 400;
        ${transition()};
      }

      .ant-menu-item-selected {
        background-color: #D21026 !important;i {
          color: #ffffff;
        }
        .anticon,
        a,
        span,
        .nav-text {
          color: #ffffff;
        }

        &:hover {
          i {
            color: #ffffff;
          }
          
          .anticon,
          a,
          .nav-text {
            color: #ffffff;
          }
        }
      }
    }

    .ant-menu-dark .ant-menu-inline.ant-menu-sub {
      background: #f1f1f1;
    }

    .ant-menu-submenu-inline
      > .ant-menu-submenu-title {
        &:hover {
          .ant-menu-submenu-arrow {
            &:before {
              background: #D21026 !important;
            }
            &:after {
              background: #D21026 !important;
            }
          }
        }
      }

    .ant-menu-submenu-inline,
    .ant-menu-submenu-vertical {
      > .ant-menu-submenu-title {
        width: 100%;
        display: flex;
        align-items: left;

        > span {
          display: flex;
          align-items: left;
        }
        .ant-menu-item-selected {
          background-color: #D21026 !important;
          .anticon {
            color: white;
          }
  
          i {
            color: #513605;
          }

          a {
            color: #ffffff !important;
          }
          span {
            color: #ffffff
          }
          .nav-text {
            color: #ffff;
          }
        }
        .ant-menu-submenu-arrow {
          left: ${props => (props['data-rtl'] === 'rtl' ? '25px' : 'auto')};
          right: ${props => (props['data-rtl'] === 'rtl' ? 'auto' : '25px')};

          &:before,
          &:after {
            width: 8px;
            ${transition()};
          }

          &:before {
            transform: rotate(-45deg) translateX(3px);
          }

          &:after {
            transform: rotate(45deg) translateX(-3px);
          }

          ${'' /* &:after {
            content: '\f123';
            font-family: 'Ionicons' !important;
            font-size: 16px;
            color: inherit;
            left: ${props => (props['data-rtl'] === 'rtl' ? '16px' : 'auto')};
            right: ${props => (props['data-rtl'] === 'rtl' ? 'auto' : '16px')};
            ${transition()};
          } */};
        }

        &:hover {
          .ant-menu-submenu-arrow {
            &:before,
            &:after {
              color: #D21026 !important;
            }
          }
        }
      }

      .ant-menu-inline,
      .ant-menu-submenu-vertical {
        > li:not(.ant-menu-item-group) {
          padding-left: ${props =>
            props['data-rtl'] === 'rtl' ? '0px !important' : '74px !important'};
          padding-right: ${props =>
            props['data-rtl'] === 'rtl' ? '74px !important' : '0px !important'};
          font-size: 13px;
          font-weight: 400;
          margin: 0;
          color: inherit;
          ${transition()};

          &:hover {
            background-color: #D21026;
            a {
                color: white !important;
              }
          }
        }

        .ant-menu-item-group {
          padding-left: 0;
          .ant-menu-item-group-title {
            padding-left: 50px !important;
            font-weight: bold !important;
          }
          .ant-menu-item-group-list {
            .ant-menu-item {
              padding-left: 75px !important;
            }
          }
        }
      }

      .ant-menu-sub {
        box-shadow: none;
        background-color: transparent !important;
      }
    }

    &.ant-layout-sider-collapsed {
      .nav-text {
        display: none;
      }

      .ant-menu-submenu-inline >  {
        .ant-menu-submenu-title:after {
          display: none;
        }
      }

      .ant-menu-submenu-vertical {
        > .ant-menu-submenu-title:after {
          display: none;
        }

        .ant-menu-sub {
          background-color: transparent !important;

          .ant-menu-item {
            height: 35px;
          }
        }
      }
    }
  }
`;

export default WithDirection(SidebarWrapper);