import actions from './actions';

const initState = {
	isLoading: false,
	isSaving: false,
	showEditVisible: false,
	documents: [],
	error: '',
	segment: '',
	section: '',
	isFiltered: false,
	filteredDocuments: [],
	searchText: '',
	showPreviewVisible: false
};

export default function reducer(
	state = initState,
	{ type, payload, newRecord }
) {
	switch (type) {
		case actions.LOAD_DOCUMENTS:
			return {
				...state,
				documents: [],
				segment: payload.segment,
				section: payload.section,
				isLoading: true,
				isFiltered: false,
				filteredDocuments: [],
				searchText: ''
			};
		case actions.LOAD_DOCUMENTS_SUCCESS:
			return {
				...state,
				isLoading: false,
				documents: payload.documents,
				error: '',
				isFiltered: false,
				filteredDocuments: [],
				searchText: ''
			};
		case actions.LOAD_DOCUMENTS_FAILED:
			return {
				...initState,
				error: payload.error
			};
		case actions.SET_TYPES:
			return {
				...initState,
				segment: payload.segment,
				section: payload.section
			};
		case actions.CLEAR_DOCUMENTS:
			return initState;
		case actions.CLEAR_FILTERS:
			return {
				...state,
				isFiltered: false,
				filteredDocuments: [],
				searchText: ''
			};
		case actions.FILTER_DOCUMENTS:
			return {
				...state,
				isFiltered: false,
				filteredDocuments: [],
				searchText: payload.searchText
			};
		case actions.FILTER_DOCUMENTS_SUCCESS:
			return {
				...state,
				isFiltered: true,
				filteredDocuments: payload.documents
			};
		case actions.FILTER_DOCUMENTS_FAILED:
			return {
				...state,
				isFiltered: false,
				filteredDocuments: [],
				searchText: ''
			};
		case actions.SAVE_DOCUMENT:
			return {
				...state,
				isSaving: true
			};
		case actions.SAVE_DOCUMENT_SUCCESS:
			return {
				...state,
				isSaving: false,
				showEditVisible: false
			};
		case actions.SAVE_DOCUMENT_FAILED:
			return {
				...state,
				isSaving: false,
				showEditVisible: false,
				error: payload.error
			};
		case actions.EDIT_DOCUMENT:
			return {
				...state,
				showEditVisible: true
			};
		case actions.EDIT_DOCUMENT_CANCEL:
			return {
				...state,
				showEditVisible: false
			};
		case actions.SHOW_PREVIEW:
			return {
				...state,
				showPreviewVisible: true
			};
		case actions.SHOW_PREVIEW_CANCEL:
			return {
				...state,
				showPreviewVisible: false
			};
		default:
			return state;
	}
}
