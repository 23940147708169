import { all, takeEvery, put, call, take } from 'redux-saga/effects';
import actions from './actions';
import { channel } from 'redux-saga';
import authActions from '../auth/actions';
import FirebaseHelper from '../../helpers/firebase/index';

function* messageReceived({ payload }) {
	console.log('messageReceived', payload);
}

function* messageClicked({ payload }) {}

export function* authStateChanged({ user }) {
	try {
		if (!user) {
			return;
		}
		const isAdmin = yield call(async () => await FirebaseHelper.isAdmin());
		console.debug(isAdmin);
		const token = yield call(
			async () =>
				await FirebaseHelper.askForPermissioToReceiveNotifications()
		);
		if (token) {
			let subscription = yield call(
				async () => await FirebaseHelper.subscribeToTopic('news')
			);
			console.debug(subscription);
			/* subscription = yield call(
				async () => await FirebaseHelper.subscribeToTopic('webnews')
			); */
		}
	} catch (error) {
		console.log(error);
	}
}

export function* onMessageReceived(): Generator<void, void, void> {
	const messageReceivedChannel = channel();
	FirebaseHelper.onPushMessageReceived(message => {
		console.log('here', message);
		messageReceivedChannel.put(actions.messageReceived({ message }));
	});

	while (true) {
		const action = yield take(messageReceivedChannel);
		yield put(action);
	}
}

export function* watchMessageReceived(): Generator<void, void, void> {
	yield call(onMessageReceived);
}

export default function* rootSaga() {
	yield all([
		takeEvery(actions.MESSAGE_RECEIVED, messageReceived),
		takeEvery(actions.MESSAGE_CLICKED, messageClicked),
		takeEvery(authActions.AUTH_STATE_CHANGED, authStateChanged)
	]);
	yield [watchMessageReceived()];
}
