import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import FirebaseHelper from '../../helpers/firebase';
import '@firebase/firestore';

const {
    // database,
    rsfFirestore,
    // processFireStoreCollection,
    getQRCode,
} = FirebaseHelper;



const COLLECTION_NAME = 'qrCodes'; // change your collection

const loadFromFirestoreAs = async() =>
    await getQRCode( /* uid */ )
    .then(data => {
        const qrCodesResult = [];
        data.data.forEach(qrCodes => {
            qrCodesResult.push(qrCodes);
        });
        return qrCodesResult;
    })
    .catch(error => {
        actions.loadFromFireStoreError(error);
    });

function* loadFromFireStore() {
    try {
        const qrCodesRes = yield call(loadFromFirestoreAs);
        yield put(actions.loadFromFireStoreSuccess(qrCodesRes));
    } catch (error) {
        console.log(error);
        yield put(actions.loadFromFireStoreError(error));
    }
}

function* storeIntoFireStore({ payload }) {
    const { data, actionName } = payload;
    console.log('data', data)
    console.log('action', actionName)
    try {

        switch (actionName) {
            case 'delete':
                yield call(rsfFirestore.deleteDocument, `${COLLECTION_NAME}/${data.uid}`, {
                    ['key']: `${data.uid}`,
                });
                break;
            case 'update':
            case 'accept':
                yield call(rsfFirestore.updateDocument, `${COLLECTION_NAME}/${data.uid}`, {
                    status: 1,
                });
                break;
            default:
                yield call(rsfFirestore.addDocument, COLLECTION_NAME, data);
                break;
        }
        yield put({ type: actions.LOAD_FROM_FIRESTORE });
    } catch (error) {
        console.log(error);
        yield put(actions.saveIntoFireStoreError(error));
    }
}

// const readAllFirestoreDocuments = async() =>
//     await database
//     .collection(COLLECTION_NAME)
//     .get()
//     .then(querySnapshot => {
//         const documents = [];
//         try {
//             querySnapshot.forEach(doc => {
//                 documents.push(doc.id);
//             });
//         } catch (e) {}
//         return documents;
//     });


export default function* rootSaga() {
    yield all([
        takeEvery(actions.LOAD_FROM_FIRESTORE, loadFromFireStore),
        takeEvery(actions.SAVE_INTO_FIRESTORE, storeIntoFireStore),
    ]);
}